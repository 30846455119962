import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { deletePhoto, firebaseError, handleUploadPhoto } from 'utils';
import {
  doc,
  collection,
  getDocs,
  deleteDoc,
  addDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
  updateDoc,
} from 'firebase/firestore';
import { db } from 'firebase.js';

export const SLIDE_SHOW_FETCH_DATA_INIT = createAction(
  'SLIDE_SHOW_FETCH_DATA_INIT',
);
export const SLIDE_SHOW_FETCH_DATA_SUCCESS = createAction(
  'SLIDE_SHOW_FETCH_DATA_SUCCESS',
);
export const SLIDE_SHOW_FETCH_DATA_FAIL = createAction(
  'SLIDE_SHOW_FETCH_DATA_FAIL',
);

export const SLIDE_SHOW_DELETE_INIT = createAction('SLIDE_SHOW_DELETE_INIT');
export const SLIDE_SHOW_DELETE_SUCCESS = createAction(
  'SLIDE_SHOW_DELETE_SUCCESS',
);
export const SLIDE_SHOW_DELETE_FAIL = createAction('SLIDE_SHOW_DELETE_FAIL');

export const SLIDE_SHOW_CLEAR_DATA = createAction('SLIDE_SHOW_CLEAR_DATA');

export const SLIDE_SHOW_CREATE_INIT = createAction('SLIDE_SHOW_CREATE_INIT');
export const SLIDE_SHOW_CREATE_SUCCESS = createAction(
  'SLIDE_SHOW_CREATE_SUCCESS',
);
export const SLIDE_SHOW_CREATE_FAIL = createAction('SLIDE_SHOW_CREATE_FAIL');

export const SLIDE_SHOW_MODIFY_INIT = createAction('SLIDE_SHOW_MODIFY_INIT');
export const SLIDE_SHOW_MODIFY_SUCCESS = createAction(
  'SLIDE_SHOW_MODIFY_SUCCESS',
);
export const SLIDE_SHOW_MODIFY_FAIL = createAction('SLIDE_SHOW_MODIFY_FAIL');

export const SLIDE_SHOW_CLEAN_UP = createAction('SLIDE_SHOW_CLEAN_UP');

export const SLIDE_SHOW_CLEAR_DATA_LOGOUT = createAction(
  'SLIDE_SHOW_CLEAR_DATA_LOGOUT',
);

export const fetchSlideShows = (all = false) => {
  return async (dispatch) => {
    dispatch(SLIDE_SHOW_FETCH_DATA_INIT());

    const slideShows = [];

    try {
      let querySnapshot;
      let q;
      const ref = collection(db, 'slideShows');
      if (all) {
        q = query(ref, orderBy('createdAt', 'desc'));
      } else {
        q = query(
          ref,
          where('active', '==', true),
          orderBy('createdAt', 'desc'),
        );
      }
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        slideShows.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return dispatch(SLIDE_SHOW_FETCH_DATA_SUCCESS({ slideShows }));
    } catch (error) {
      console.log(error);
      toastr.error('', error);
      return dispatch(SLIDE_SHOW_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deleteSlideShow = (id) => {
  return async (dispatch, getState) => {
    dispatch(SLIDE_SHOW_DELETE_INIT());
    const { locale } = getState().preferences;

    const deleteSlideShowTask = await deleteDoc(doc(db, 'slideShows', id));

    try {
      await Promise.all([deleteSlideShowTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SLIDE_SHOW_DELETE_FAIL({
          error: errorMessage,
        }),
      );
    }

    toastr.success('', 'The Slide Show was deleted.');
    return dispatch(SLIDE_SHOW_DELETE_SUCCESS({ id }));
  };
};

export const clearSlideShowsData = () => {
  return (dispatch) => {
    dispatch(SLIDE_SHOW_CLEAR_DATA());
  };
};

export const clearSlideShowsDataLogout = () => {
  return (dispatch) => {
    dispatch(SLIDE_SHOW_CLEAR_DATA_LOGOUT());
  };
};

export const createSlideShow = (data) => {
  return async (dispatch, getState) => {
    dispatch(SLIDE_SHOW_CREATE_INIT());
    const { locale } = getState().preferences;

    try {
      // Handle Upload New Image
      const imageList = data.images?.map(async (item) => ({
        url: await handleUploadPhoto({
          folderName: 'slideShows',
          file: item.file,
          url: item.url,
        }),
        sortOrder: item.sortOrder,
      }));

      const imageListPromises = imageList && (await Promise.all(imageList));

      const slideShow = {
        ...data,
        images: imageListPromises,
        createdAt: serverTimestamp(),
      };
      delete slideShow.removedImage;

      const createSlideShowDbTask = await addDoc(
        collection(db, 'slideShows'),
        slideShow,
      );

      slideShow.id = createSlideShowDbTask.id;
      toastr.success('', 'Slide Show created successfully');
      return dispatch(SLIDE_SHOW_CREATE_SUCCESS(slideShow));
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SLIDE_SHOW_CREATE_FAIL({
          error: errorMessage,
        }),
      );
    }
  };
};

export const modifySlideShow = (data) => {
  return async (dispatch, getState) => {
    dispatch(SLIDE_SHOW_MODIFY_INIT());
    const { locale } = getState().preferences;

    try {
      // Remove image
      data.removedImage &&
        (await Promise.all(
          data.removedImage.map(async (item) => {
            await deletePhoto(item.url);
          }),
        ));

      // Handle Upload New Image
      const imageList = data.images?.map(async (item) => ({
        url: await handleUploadPhoto({
          folderName: 'slideShows',
          file: item.file,
          url: item.url,
        }),
        sortOrder: item.sortOrder,
      }));

      const imageListPromises = imageList && (await Promise.all(imageList));

      const slideShowData = {
        ...data,
        images: imageListPromises,
        modifiedAt: serverTimestamp(),
      };

      delete slideShowData.removedImage;
      const updateSlideShowDbTask = await updateDoc(
        doc(db, 'slideShows', slideShowData.id),
        slideShowData,
      );
      await Promise.all([updateSlideShowDbTask]);
      toastr.success('', 'Slide Show updated successfully');

      return dispatch(SLIDE_SHOW_MODIFY_SUCCESS(slideShowData));
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SLIDE_SHOW_MODIFY_FAIL({
          error: errorMessage,
        }),
      );
    }
  };
};

export const slideShowsCleanUp = () => (dispatch) =>
  dispatch(SLIDE_SHOW_CLEAN_UP());

import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db, storage } from 'firebase.js';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';
import { stripe } from 'stripe.js';

export const getPromotionBySellerIdSnapShot = (
  id,
  setPromotions,
  setLoading,
) => {
  try {
    const q = query(
      collection(db, 'promotions'),
      where('sellerId', '==', id),
      orderBy('createdAt', 'desc'),
    );
    onSnapshot(q, async (querySnapshot) => {
      setLoading(true);
      const data = [];
      // const customersData = await getCustomers();
      querySnapshot.docs.map(async (doc) => {
        const docData = doc.data();
        // docData.customerData = customersData.find(
        //   (val) => val.id === docData.customerId,
        // );
        data.push({
          ...docData,
          id: doc.id,
        });
      });
      setPromotions(data);
      setLoading(false);
    });
  } catch (err) {
    setLoading(false);
    setPromotions([]);
  }
};

export const createPromotion = async (coupon, data) => {
  try {
    await stripe.promotionCodes.create({
      coupon: coupon,
      code: data.code,
      active: data.active,
    });

    toastr.success('', 'Create Promotion Succesfully');
  } catch (error) {
    toastr.error('', error);
  }
};

export const updatePromotion = async (data) => {
  try {
    await stripe.promotionCodes.update(data.id, {
      active: data.active,
    });

    toastr.success('', 'Update Promotion Successfully');
  } catch (error) {
    console.log(error);
    toastr.error('', error);
  }
};

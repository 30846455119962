import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db, storage } from 'firebase.js';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';

export const getReviewBySellerIdSnapShot = (id, setReviews, setLoading) => {
  try {
    const q = query(
      collection(db, 'reviews'),
      where('sellerId', '==', id),
      orderBy('createdAt', 'desc'),
    );
    onSnapshot(q, async (querySnapshot) => {
      setLoading(true);
      const data = [];
      // const customersData = await getCustomers();
      querySnapshot.docs.map(async (doc) => {
        const docData = doc.data();
        // docData.customerData = customersData.find(
        //   (val) => val.id === docData.customerId,
        // );
        data.push({
          ...docData,
          id: doc.id,
        });
      });
      setReviews(data);
      setLoading(false);
    });
  } catch (err) {
    setLoading(false);
    setReviews([]);
  }
};

const deletePhoto = async (photoURL) => {
  const storageRef = ref(storage, photoURL);
  await deleteObject(storageRef)
    .then(() => {
      // File deleted successfully
    })
    .catch(() => {
      // Uh-oh, an error occurred!
    });
};

const handleUploadAvatar = async ({ file, photoURL }) => {
  if (!file) {
    if (photoURL) {
      return photoURL;
    }
    return null;
  } else {
    if (photoURL) {
      await deletePhoto(photoURL);
    }
    const snapshot = await uploadBytes(
      ref(storage, `reviews/${new Date().getTime()}`),
      file,
      { contentType: 'image/jpeg' },
    );
    return `https://firebasestorage.googleapis.com/v0/b/${
      snapshot.ref.bucket
    }/o/${encodeURIComponent(snapshot.ref.fullPath)}?alt=media`;
  }
};

export const createReview = async (data) => {
  try {
    console.log();
    await addDoc(collection(db, 'reviews'), {
      comment: data.comment,
      rating: data.rating,
      sellerId: data.sellerId,
      customerId: data.customerId || null,
      customerName: data.customerName,
      customerAvatar: await handleUploadAvatar({
        file: data.file,
        photoURL: data.customerAvatar,
      }),
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
    });
    toastr.success('', 'Create Review Succesfully');
  } catch (error) {
    toastr.error('', error);
  }
};

export const updateReview = async (data) => {
  try {
    await updateDoc(doc(db, 'reviews', data.id), {
      comment: data.comment,
      rating: data.rating,
      sellerId: data.sellerId,
      customerId: data.customerId || null,
      customerName: data.customerName,
      customerAvatar: await handleUploadAvatar({
        file: data.file,
        photoURL: data.customerAvatar,
      }),
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      modifiedAt: Timestamp.now(),
    });
    toastr.success('', 'Update Review Successfully');
  } catch (error) {
    console.log(error);
    toastr.error('', error);
  }
};

export const deleteReview = async (reviewId, data) => {
  try {
    if (data?.customerAvatar) {
      await deletePhoto(data.customerAvatar);
    }
    await deleteDoc(doc(db, 'reviews', reviewId));
    toastr.success('', 'Delete Review Successfuly');
  } catch (error) {
    toastr.error('', error);
  }
};

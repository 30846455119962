import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

import { authReducer } from './auth';
import { usersReducer } from './users';
import { customersReducer } from './customers';
import { preferencesReducer } from './preferences';
import { sellersReducer } from './sellers';
import { classCategoriesReducer } from './classCategories';
import { classesReducer } from 'state/reducers/classes';
import { plansReducer } from 'state/reducers/plans';
import { bookingsReducer } from 'state/reducers/bookings';
import { slideShowsReducer } from './slideShows';
import { vouchersReducer } from './vouchers';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading']
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage: new CookieStorage(Cookies) },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading']
    },
    usersReducer
  ),
  customers: persistReducer(
    {
      key: 'customers',
      storage,
      blacklist: ['error', 'loading']
    },
    customersReducer
  ),
  sellers: persistReducer(
    {
      key: 'sellers',
      storage,
      blacklist: ['error', 'loading']
    },
    sellersReducer
  ),
  classCategories: persistReducer(
    {
      key: 'classCategories',
      storage,
      blacklist: ['error', 'loading']
    },
    classCategoriesReducer
  ),
  slideShows: persistReducer(
    {
      key: 'slideShows',
      storage,
      blacklist: ['error', 'loading']
    },
    slideShowsReducer
  ),
  vouchers: persistReducer(
    {
      key: 'vouchers',
      storage,
      blacklist: ['error', 'loading']
    },
    vouchersReducer
  ),
  classes: persistReducer(
    {
      key: 'classes',
      storage,
      blacklist: ['error', 'loading']
    },
    classesReducer
  ),
  plans: persistReducer(
    {
      key: 'plans',
      storage,
      blacklist: ['error', 'loading']
    },
    plansReducer
  ),
  bookings: persistReducer(
    {
      key: 'bookings',
      storage,
      blacklist: ['error', 'loading']
    },
    bookingsReducer
  ),
  toastr: toastrReducer,
});

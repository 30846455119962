import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import { bookingStatus } from 'components/options';

import paths from 'pages/Router/paths';
import { bookingsCleanUp, clearBookingsData, fetchBookings } from 'state/actions/bookings';
import { useChangeHandler, useFormatMessage } from 'hooks';
import DatePickerStyled from 'components/DatePicker';
import { fetchUsers } from 'state/actions/customers';
import { fetchSellers } from 'state/actions/sellers';
import { fetchClasses } from 'state/actions/classes';

const BookingForm = ({ bookingData, action }) => {
  const { sellers, customers, classes, loading } = useSelector(
    state => ({
      loading: state.bookings.loading,
      sellers: state.sellers.data,
      customers: state.customers.data,
      classes: state.classes.data
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(fetchUsers(true));
      dispatch(fetchSellers(true));
      dispatch(fetchClasses(true));
    if (!bookingData.id) dispatch(clearBookingsData());
    return () => dispatch(bookingsCleanUp());
  }, [bookingData.id, dispatch]);

  if (!bookingData.id) {
    bookingData = {
      status: 'Pending',
      startDate: new Date().getTime(),
      endDate: new Date().getTime()
    }
  }
  const [booking, setBooking] = useState(bookingData);

  const onChangeHandler = useChangeHandler(setBooking);

  const [validated, setValidated] = useState(false);

  const onSubmitHandler = event => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(
        action({ ...booking })
      );
    }
    setValidated(true);
  };
  let credit = 0;
  if (booking.classId && !bookingData.id) {
    const classItem = Object.values(classes).filter((x) => x.id == booking.classId);
    if (classItem.length > 0) {
      credit = classItem[0].credit;
      booking.credit = credit;
    }
  }
  console.log(booking);
  return (
    <Row>
      <Col lg={12}>
        <Form noValidate validated={validated}  onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label htmlFor="customer">
              Customer *
            </Form.Label>
            <Form.Select id="customer" name="customerId" placeholding="Select Customer"
                         value={booking.customerId}
                         required
                         onChange={onChangeHandler}>
              <option value=''>Select Customer</option>
              {Object.values(customers).map((customer) => <option key={customer.id}
                                                              value={customer.id}>{customer.firstName} {customer.lastName} - {customer.email}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select customer
            </Form.Control.Feedback>
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="customer">
              Seller *
            </Form.Label>
            <Form.Select id="seller" name="sellerId" aria-label="Select Seller"
                         value={booking.sellerId}
                         required
                         onChange={onChangeHandler}>
              <option value=''>Select Seller</option>
              {Object.values(sellers).map((seller) => <option key={seller.id}
                                                              value={seller.id}>{seller.name}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select seller
            </Form.Control.Feedback>
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="class">
              Class *
            </Form.Label>
            <Form.Select id="class" name="classId" aria-label="Select Class"
                         value={booking.classId}
                         required
                         onChange={onChangeHandler}>
              <option value=''>Select Class</option>
              {Object.values(classes).filter((x) => x.sellerId == booking.sellerId).map(
                (item) => <option key={item.id} value={item.id}>{item.name}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select class
            </Form.Control.Feedback>
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="status">
              Status *
            </Form.Label>
            <Form.Select id="status" name="status" aria-label="Select Status"
                         value={booking.status}
                         onChange={onChangeHandler}>
              {Object.values(bookingStatus).map((value) => <option key={value}
                                                                     value={value}>{value}</option>)}
            </Form.Select>
          </Form.Group>
          <br/>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="credit">
              Credits *
            </Form.Label>
            <Form.Control
              id="credit"
              type="number"
              required
              name="credit"
              value={booking.credit}
            />
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="startDate">
              Start Date
            </Form.Label>
            <br/>
           <DatePickerStyled
             setState={setBooking}
             showTimeSelect={true} date={booking.startDate ? (booking.startDate.seconds ? new Date(booking.startDate.seconds*1000) : booking.startDate) : new Date().getTime()} name="startDate"/>
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="endDate">
              End Date
            </Form.Label>
            <br/>
            <DatePickerStyled
              setState={setBooking}
              showTimeSelect={true} date={booking.endDate ? (booking.endDate.seconds ? new Date(booking.endDate.seconds*1000) : booking.endDate) : new Date().getTime()} name="endDate"/>
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="note">
              Note
            </Form.Label>
            <Form.Control
              id="note"
              as="textarea"
              name="note"
              rows={3}
              value={booking.note}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br/>
          <hr/>
          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading &&
              'is-loading'}`}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_BOOKING} className="button">
              {useFormatMessage('UserForm.goBack')}
            </Link>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};

BookingForm.propTypes = {
  bookingData: PropTypes.shape({
    id: PropTypes.string,
    customerId: PropTypes.string.isRequired,
    sellerId: PropTypes.string,
    classId: PropTypes.string,
    credit: PropTypes.number,
    status: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  action: PropTypes.func.isRequired
};

export default BookingForm;

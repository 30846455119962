import { createIntl, createIntlCache } from 'react-intl';
import { useNavigate } from 'react-router';
import React from 'react';

import english from 'languages/en.json';
import en from 'assets/en.png';
import { Timestamp } from '@firebase/firestore';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';
import { storage } from 'firebase.js';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'auth/email-already-exists',
  EMAIL_INVALID: 'auth/invalid-email',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  PASSWORD_INVALID: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  QUOTA_EXCEEDED_STORAGE: 'storage/quota-exceeded',
  UNAUTHENTICATED_STORAGE: 'storage/unauthenticated',
  UNAUTHORIZED_STORAGE: 'storage/unauthorized',
};

export const messages = {
  en: english,
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache,
  );
};

export const firebaseError = (error, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const validateEmail = (email) =>
  email.match(
    // eslint-disable-next-line no-useless-escape
    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
  );
export const idRegex = /^[a-zA-Z0-9_-]{1,200}$/;

export const validateId = (id) => idRegex.test(id);

export const validateSGPhone = (phone) => phone.match(/^\+65[8|9]{1}[0-9]{7}$/);

export const validateRating = (rating) => rating >= 0 && rating <= 5;

export const inputValidations = (email, password, locale) => {
  let inputs = {
    email: {
      modifier: null,
      message: null,
    },
    password: {
      modifier: null,
      message: null,
    },
    canSubmit: null,
  };
  const intl = getIntlContext(locale);

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  const isValidEmail = email && validateEmail(email);

  if (email && !isValidEmail) {
    setInputs('email', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.invalidEmail' }),
    });
  }

  const isValidPassword = password && password.length >= 6;

  if (isValidPassword) {
    setInputs('password', {
      modifier: 'is-success',
      message: intl.formatMessage({ id: 'utils.safePassword' }),
    });
  } else if (password) {
    setInputs('password', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.unsafePassword' }),
    });
  }

  if (isValidEmail && isValidPassword) {
    setInputs('canSubmit', true);
  }

  return inputs;
};

export const sortDate = (a, b) => {
  const c = new Date(a.original.createdAt.toDate()).getTime();
  const d = new Date(b.original.createdAt.toDate()).getTime();
  return d > c ? 1 : -1;
};

export const sortDate2 = (a, b) => {
  const c = new Date(a.original.created * 1000).getTime();
  const d = new Date(b.original.created * 1000).getTime();
  return d > c ? 1 : -1;
};

export const sortDateVoucher = (a, b) => {
  const c = new Date(a.original.redeem_by).getTime();
  const d = new Date(b.original.redeem_by).getTime();
  return d > c ? 1 : -1;
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
};

export const withRouter = (Component) => {
  function Wrapper(props) {
    const history = useNavigate();
    /* eslint-disable react/jsx-props-no-spreading */
    return <Component history={history} {...props} />;
  }
  return Wrapper;
};

export const convertFBTimestamp = (date) => {
  return new Date(date.toDate()).toLocaleString('en-SG', {
    timeZone: 'Asia/Singapore',
  });
};

export const convertTimestampToFBTimeStamp = (date) => {
  return date.seconds ? date : Timestamp.fromDate(new Date(date));
};

export const deletePhoto = async (photoURL) => {
  const storageRef = ref(storage, photoURL);
  await deleteObject(storageRef)
    .then(() => {
      // File deleted successfully
    })
    .catch(() => {
      // Uh-oh, an error occurred!
    });
};

export const handleUploadPhoto = async ({ folderName, file, url }) => {
  if (!file) {
    return url;
  } else {
    const snapshot = await uploadBytes(
      ref(storage, `${folderName}/${new Date().getTime()}`),
      file,
      {
        contentType: 'image/jpeg',
      },
    );
    return `https://firebasestorage.googleapis.com/v0/b/${
      snapshot.ref.bucket
    }/o/${encodeURIComponent(snapshot.ref.fullPath)}?alt=media`;
  }
};

const blobUrlRegex = /^blob:/i;
export function checkIsBlob(url) {
  return blobUrlRegex.test(url);
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

import DatePickerStyled from 'components/DatePicker';
import { Timestamp } from 'firebase/firestore';
import { useChangeHandler } from 'hooks';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import { validateRating } from 'utils';

export default function ReviewModal({
  selectedReview,
  show,
  onClose,
  onSubmit,
  loading,
}) {
  const [review, setReview] = useState(selectedReview);
  const onChangeHandler = useChangeHandler(setReview);
  const onFileChangedHandler = (e) => {
    const file = e.target.files[0];
    setReview((prevState) => ({ ...prevState, file, customerAvatar: null }));
  };
  const handleRating = (rate) => {
    setReview((prev) => {
      return {
        ...prev,
        rating: rate,
      };
    });
  };

  const imagePreviewUrl = !review?.customerAvatar
    ? review?.file && URL.createObjectURL(review?.file)
    : review?.customerAvatar;

  useEffect(() => {
    setReview({
      ...selectedReview,
      createdAt: selectedReview?.createdAt
        ? new Date(selectedReview.createdAt.seconds * 1000)
        : new Date().getTime(),
    });
  }, [show]);

  const canSubmit =
    review?.rating &&
    review?.comment &&
    review?.customerName &&
    review?.createdAt;
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title
          className="fw-bold"
          style={{
            color: 'black',
            fontSize: '18px',
          }}
        >
          {selectedReview ? 'Update' : 'Add'} Review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label htmlFor="name">Avatar</Form.Label>
          <div className="file has-name">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                accept="image/*"
                onChange={onFileChangedHandler}
              />
            </label>
            {imagePreviewUrl && (
              <>
                <div className="is-user-avatar image has-max-width is-aligned-center">
                  <img
                    className="user-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                </div>
              </>
            )}
          </div>
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Label htmlFor="name">Comment *</Form.Label>
          <Form.Control
            id="name"
            type="text"
            required
            name="comment"
            value={review?.comment}
            onChange={onChangeHandler}
            as={'textarea'}
          />
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Label htmlFor="name">Rating *</Form.Label>
          <br />
          <Rating onClick={handleRating} initialValue={review?.rating || 0} />
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Label htmlFor="name">Customer Name *</Form.Label>
          <Form.Control
            id="name"
            type="text"
            required
            name="customerName"
            value={review?.customerName}
            onChange={onChangeHandler}
          />
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Label htmlFor="createdAt">Created Date</Form.Label>
          <br />
          <DatePickerStyled
            setState={setReview}
            showTimeSelect={true}
            date={
              review && review.createdAt
                ? review.createdAt.seconds
                  ? new Date(review.createdAt.seconds * 1000)
                  : review.createdAt
                : new Date().getTime()
            }
            name="createdAt"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onSubmit(review);
            setReview();
          }}
          className={`btn btn-primary ${loading && 'is-loading'}`}
          disabled={!canSubmit || loading}
        >
          {selectedReview ? 'Update' : 'Add'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

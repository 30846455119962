import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import {
  doc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  query,
  where,
  serverTimestamp,
  orderBy
} from 'firebase/firestore';
import { getStorage, ref, deleteObject, uploadBytes } from 'firebase/storage';
import { db } from 'firebase.js';

export const SELLER_FETCH_DATA_INIT = createAction('SELLER_FETCH_DATA_INIT');
export const SELLER_FETCH_DATA_SUCCESS = createAction(
  'SELLER_FETCH_DATA_SUCCESS'
);
export const SELLER_FETCH_DATA_FAIL = createAction('SELLER_FETCH_DATA_FAIL');

export const SELLER_DELETE_INIT = createAction('SELLER_DELETE_INIT');
export const SELLER_DELETE_SUCCESS = createAction(
  'SELLER_DELETE_SUCCESS'
);
export const SELLER_DELETE_FAIL = createAction('SELLER_DELETE_FAIL');

export const SELLER_CLEAR_DATA = createAction('SELLER_CLEAR_DATA');

export const SELLER_CREATE_INIT = createAction('SELLER_CREATE_INIT');
export const SELLER_CREATE_SUCCESS = createAction(
  'SELLER_CREATE_SUCCESS'
);
export const SELLER_CREATE_FAIL = createAction('SELLER_CREATE_FAIL');

export const SELLER_MODIFY_INIT = createAction('SELLER_MODIFY_INIT');
export const SELLER_MODIFY_SUCCESS = createAction(
  'SELLER_MODIFY_SUCCESS'
);
export const SELLER_MODIFY_FAIL = createAction('SELLER_MODIFY_FAIL');

export const SELLER_CLEAN_UP = createAction('SELLER_CLEAN_UP');

export const SELLER_CLEAR_DATA_LOGOUT = createAction('SELLER_CLEAR_DATA_LOGOUT');

const storage = getStorage();

export const fetchSellers = ( all = false) => {
  return async (dispatch) => {

    dispatch(SELLER_FETCH_DATA_INIT());

    const sellers = [];

    try {
      let querySnapshot;
      const ref = collection(db, 'sellers');
      let q;
      if (all) {
        q = query(ref, orderBy('createdAt', 'desc'));
      } else {
        q = query(ref, where('active', '==', true),
          orderBy('createdAt', 'desc')
        );
      }
      querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        sellers.push({
          id: doc.id,
          ...doc.data()
        });
      });
      return dispatch(
        SELLER_FETCH_DATA_SUCCESS({ sellers })
      );
    } catch (error) {
      toastr.error('', error);
      return dispatch(SELLER_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deleteSeller = id => {
  return async (dispatch, getState) => {
    dispatch(SELLER_DELETE_INIT());
    const { locale } = getState().preferences;

    const { photoURL } = getState()
      .sellers.data.filter(seller => seller.id === id)
      .pop();

    const deleteLogoTask = photoURL ? deleteLogo(photoURL) : null;

    const deleteSellerTask = await deleteDoc(doc(db, 'sellers', id));

    try {
      await Promise.all([deleteLogoTask, deleteSellerTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SELLER_DELETE_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The seller was deleted.');
    return dispatch(SELLER_DELETE_SUCCESS({ id }));
  };
};

export const clearSellersData = () => {
  return dispatch => {
    dispatch(SELLER_CLEAR_DATA());
  };
};

export const clearSellersDataLogout = () => {
  return dispatch => {
    dispatch(SELLER_CLEAR_DATA_LOGOUT());
  };
};

export const createSeller = (data ) => {
  return async (dispatch, getState) => {
    dispatch(SELLER_CREATE_INIT());
    const { locale } = getState().preferences;

    let uploadLogoTask = null;
    let photoURL = null;


    const seller = {
      ...data,
      createdAt: serverTimestamp()
    };
    delete seller.file;


    try {
      const createSellerDbTask = await addDoc(collection(db, 'sellers'), seller);
      seller.id = createSellerDbTask.id;
      if (data.file) {
        photoURL = getLogoUrl(seller.id, data.file);
        await uploadLogo(seller.id, data.file);
        await updateDoc(doc(db, 'sellers', seller.id), {
          photoURL
        });
      }

    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SELLER_CREATE_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Seller created successfully');
    return dispatch(SELLER_CREATE_SUCCESS(seller));
  };
};

const uploadLogo = (uid, file) => {

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;
  const storageRef = ref(storage, `sellers/${fileName}`);
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log('Uploaded a blob or file!');
  });
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/sellers%2F${uid}_200x200.${fileExtension}?alt=media`;
};
const deleteLogo = oldLogo => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo
    .split('sellers%2F')
    .pop()
    .split('?alt=media')
    .shift();
  const desertRef = ref(storage, logoPath);
  return deleteObject(desertRef).then(() => {
    // File deleted successfully
  }).catch(() => {
    // Uh-oh, an error occurred!
  });
};

export const modifySeller = (data) => {
  return async (dispatch, getState) => {
    dispatch(SELLER_MODIFY_INIT());
    const { locale } = getState().preferences;
    const { photoURL } = getState()
      .sellers.data.filter(seller => seller.id === data.id)
      .pop();

    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (data.file) {
      newLogoUrl = getLogoUrl(data.id, data.file);
      deleteLogoTask = photoURL && deleteLogo(photoURL);
      uploadLogoTask = uploadLogo(data.id, data.file);
    }
    const sellerData = {
      ...data,
      photoURL: newLogoUrl || photoURL || null,
      modifiedAt: serverTimestamp()
    };
    delete sellerData.file;
    const updateSellerDbTask = await updateDoc(doc(db, 'sellers', data.id), sellerData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateSellerDbTask]);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SELLER_MODIFY_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Seller updated successfully');

    return dispatch(SELLER_MODIFY_SUCCESS(sellerData));
  };
};

export const sellersCleanUp = () => dispatch => dispatch(SELLER_CLEAN_UP());

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import ClassForm from '../../components/ClassForm';
import { createClass, modifyClass } from '../../state/actions/classes';
import paths from '../Router/paths';
import { doc, getDoc } from '@firebase/firestore';
import { db } from 'firebase.js';

const Class = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.classes.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [classItem, setClassItem] = useState({});

  useEffect(() => {
    const fetchClassData = async () => {
      const classRef = doc(db, 'classes', id);
      const response = await getDoc(classRef);
      return response.data();
    };

    if (id) {
      fetchClassData()
        .then(classItem => {
          setClassItem({
            ...classItem,
            createdAt: classItem.createdAt,
            id
          });
        })
        .catch(() => {
          setClassItem({ error: true });
        });
    }

  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Navigate to={paths.ROOT}/>;
  const redirect = (classItem.error || success) && <Navigate to={paths.MANAGE_CLASS}/>;
  const classForm =
    !classItem.name && id ? (
      <ClipLoader/>
    ) : (
      <ClassForm
        classData={classItem}
        action={isEditing ? modifyClass : createClass}
      />
    );
  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">{isEditing ? 'Edit Class' : 'Add Class'}</Card.Title>
            </Card.Header>
            <Card.Body>
              {classForm}
            </Card.Body>

          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Class;

import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage } from 'hooks';
import UserForm from 'components/UserForm';
import { doc, getDoc } from 'firebase/firestore';
import { modifyUser } from 'state/actions/users';
import ChangePassword from '../ChangePassword';
import { db } from 'firebase.js';


const Profile = () => {
  const { userData } = useSelector(
    state => ({
      userData: state.auth.userData
    }),
    shallowEqual
  );
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUserData = async (id) => {
      const userRef = doc(db, 'users', id);
      const response = await getDoc(userRef);
      return response.data();
    };
    if (userData.id) {
      const user = fetchUserData(userData.id)
        .then(result => {
          setUser({
            ...result,
            createdAt: result.createdAt,
            id: userData.id,
            isAdmin: result.isAdmin
          });
        })
        .catch(() => {
          setUser({ error: true });
        });
    }
  }, [userData]);

  return (
    <Card>
      <Card.Header>
        <div className='d-flex flex-row align-items-center'>
          <Card.Title as="h5">{useFormatMessage('Profile.profile')}</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        {user.id ? <UserForm isEditing isProfile userData={user} action={modifyUser}/> : <ClipLoader/>}
        <ChangePassword/>
      </Card.Body>
    </Card>

  );
};

export default Profile;

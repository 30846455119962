import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import BookingForm from '../../components/BookingForm';
import { createBooking, modifyBooking } from '../../state/actions/bookings';
import paths from '../Router/paths';
import { doc, getDoc } from '@firebase/firestore';
import { db } from 'firebase.js';

const Booking = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.bookings.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [booking, setBooking] = useState({});

  useEffect(() => {
    const fetchBookingData = async () => {
      const bookingRef = doc(db, 'bookings', id);
      const response = await getDoc(bookingRef);
      return response.data();
    };

    if (id) {
      fetchBookingData()
        .then(bookingData => {
          console.log(bookingData);
          setBooking({
            ...bookingData,
            createdAt: bookingData.createdAt,
            id
          });
        })
        .catch(() => {
          setBooking({ error: true });
        });
    }

  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Navigate to={paths.ROOT}/>;
  const redirect = (booking.error || success) && <Navigate to={paths.MANAGE_BOOKING}/>;
  const bookingForm =
    !booking.customerId && id ? (
      <ClipLoader/>
    ) : (
      <BookingForm
        bookingData={booking}
        action={isEditing ? modifyBooking : createBooking}
      />
    );

  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">{isEditing ? 'Edit Booking' : 'Add Booking'}</Card.Title>
            </Card.Header>
            <Card.Body>
              {bookingForm}
            </Card.Body>

          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Booking;

import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import UserForm from 'components/UserForm';
import { createUser, modifyUser } from 'state/actions/users';
import paths from 'pages/Router/paths';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'firebase.js';
import { useFormatMessage } from 'hooks';
import { Card } from 'react-bootstrap';

function User () {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.users.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [user, setUser] = useState({
    name: '',
    email: '',
    isAdmin: false,
    getBookingEmail: false,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, "users", id);
      const response = await getDoc(userRef);
      return response.data();
    };

    if (id) {
      fetchUserData()
        .then(userData => {
          setUser({
            ...userData,
            createdAt: userData.createdAt,
            id,
            isAdmin: userData.isAdmin,
            getBookingEmail: userData.getBookingEmail
          });
        })
        .catch(() => {
          setUser({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const userForm =
    !user.name && id ? (
      <ClipLoader/>
    ) : (
      <UserForm
        isEditing={isEditing}
        userData={user}
        action={isEditing ? modifyUser : createUser}
      />
    );

  const NavigateAdmin = !isAdmin && <Navigate to={paths.ROOT}/>;
  const NavigateUser = (user.error || success) && <Navigate to={paths.MANAGE_USER}/>;

  const editUserMessage = useFormatMessage('User.editUser');

  const newUserMessage = useFormatMessage('User.newUser');

  return (
    <div>
      {NavigateAdmin}
      {NavigateUser}
      <Card>
        <Card.Header>
          <Card.Title as="h5">{isEditing ? editUserMessage : newUserMessage}</Card.Title>
        </Card.Header>
        <Card.Body>{userForm}</Card.Body>
      </Card>

    </div>
  );
};

export default User;

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { createCategory, modifyCategory } from '../../state/actions/classCategories';
import paths from '../Router/paths';
import { doc, getDoc } from '@firebase/firestore';
import { db } from 'firebase.js';
import CategoryForm from 'components/CategoryForm';

const ClassCategory = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.classCategories.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [category, setCategory] = useState({});

  useEffect(() => {
    const fetchCategoryData = async () => {
      const categoryRef = doc(db, 'classCategories', id);
      const response = await getDoc(categoryRef);
      return response.data();
    };

    if (id) {
      fetchCategoryData()
        .then(categoryData => {
          setCategory({
            ...categoryData,
            createdAt: categoryData.createdAt,
            id
          });
        })
        .catch(() => {
          setCategory({ error: true });
        });
    }

  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Navigate to={paths.ROOT}/>;
  const redirect = (category.error || success) && <Navigate to={paths.MANAGE_CLASS_CAT}/>;
  const categoryForm =
    !category.name && id ? (
      <ClipLoader/>
    ) : (
      <CategoryForm
        categoryData={category}
        action={isEditing ? modifyCategory : createCategory}
      />
    );
  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">{isEditing ? 'Edit Category' : 'Add Category'}</Card.Title>
            </Card.Header>
            <Card.Body>
              {categoryForm}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClassCategory;

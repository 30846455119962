import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db, functions } from 'firebase.js';
import { httpsCallable } from 'firebase/functions';
export const getCustomerById = async (id) => {
  try {
    const docRef = doc(db, 'customers', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists() && docSnap.data().active) {
      return {
        ...docSnap.data(),
        id: docSnap.id,
      };
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCustomers = async () => {
  try {
    const data = [];
    const q = query(
      collection(db, 'customers'),
      and(where('active', '==', true)),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const docData = doc.data();
      data.push({
        ...docData,
        id: doc.id,
      });
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const cancelPlan = (subId) => {
  const cancelCall = httpsCallable(functions, 'cancelPlan');
  return cancelCall({
    subId,
  });
};

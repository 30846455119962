import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';
import { useFormatDate } from 'hooks';
import { sortDate } from 'utils';
import { Timestamp } from 'firebase/firestore';
import { getPartners } from 'lib/partner';
import { getClassCategories } from 'lib/class';

export default function Partners() {
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [partnerList, setPartnerList] = useState([]);
  const [classCategoriesList, setClassCategoriesList] = useState([]);
  const { isAdmin } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );
  const fetchPartner = async () => {
    await getPartners(setPartnerList, setLoading);
  };

  const fetchClassCategories = async () => {
    await getClassCategories(setClassCategoriesList)
  } 
  useEffect(() => {
    if (isAdmin) {
      fetchClassCategories()  
      fetchPartner();
    }
  }, [isAdmin]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const columns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Sport Type',
      accessor: 'sportType',
      Cell: ({ row }) => (classCategoriesList.find((val) => val.id === row.original.sportType).name),
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Company Url',
      accessor: 'companyUrl',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
  ];

  const data = search
    ? partnerList.filter((el) => {
        const clonedElem = {
          email: el.email,
          phone: el.phone,
          name: el.name,
          sportType: classCategoriesList.find((val) => val.id === el.sportType).name
        };
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : partnerList;

  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage Partner</Card.Title>
            <NavSearch handleChange={setSearch} />
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import NavBar from './NavBar';
import Navigation from './Navigation';
import Footer from './Footer';

import '../../assets/scss/style.scss';
import { collapseMenuAction } from '../../state/actions/preferences';
import ScrollToTop from './ScrollToTop';

function Layout ({ windowWidth, children }) {
  const {
    collapseMenu,
    layout
  } = useSelector(
    state => ({
      collapseMenu: state.preferences.collapseMenu,
      layout: state.preferences.layout
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const onComponentWillMount = () => {
    dispatch(collapseMenuAction());
  };

  useEffect(() => {
    if (windowWidth > 992 && windowWidth <= 1024 && layout !== 'horizontal') {
      onComponentWillMount();
    }
  });

  const mobileOutClickHandler = () => {
    if (windowWidth < 992 && collapseMenu) {
      onComponentWillMount();
    }
  };
  return (
    <ScrollToTop>
      <Navigation/>
      <NavBar/>
      <div className="pcoded-main-container" onClick={() => mobileOutClickHandler}>
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </ScrollToTop>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

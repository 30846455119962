import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function Link ({ children, to, className, onClick }) {
  return (
    <NavLink
      to={to}
      className={className || 'navbar-item'}
      onClick={onClick}
      exact
    >
      {children}
    </NavLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Link;

import { updatePlanConfig } from 'lib/config';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';

export default function PlanConfigModal({
  planConfigProps,
  show,
  onClose,
  onSubmit,
  loading,
}) {
  const [planConfig, setPlanConfig] = useState(planConfigProps);
  const [validated, setValidated] = useState(false);

  const onSubmitHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      await updatePlanConfig(planConfig);
      onClose();
    }
    setValidated(true);
  };

  useEffect(() => {
    setPlanConfig({
      ...planConfigProps,
    });
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
        <Modal.Header closeButton>
          <Modal.Title
            className="fw-bold"
            style={{
              color: 'black',
              fontSize: '18px',
            }}
          >
            Update Plan Content
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label htmlFor="description">
              <button
                style={{
                  marginLeft: '8px',
                }}
                className="btn btn-primary btn-sm"
                onClick={(e) => {
                  setPlanConfig((prev) => ({
                    ...prev,
                    addDescription:
                      prev?.['addDescription']?.length >= 0
                        ? [...prev['addDescription'], '']
                        : [''],
                  }));
                }}
              >
                +
              </button>
            </Form.Label>
            {planConfig?.addDescription?.map((e, index) => (
              <InputGroup
                style={{
                  marginBottom: '4px',
                }}
              >
                <Form.Control
                  id="addDescription"
                  required
                  name={`addDescription.${index}`}
                  value={planConfig?.addDescription?.[index]}
                  onChange={(e) => {
                    const addDescription = planConfig['addDescription'];
                    addDescription[index] = e.target.value;
                    setPlanConfig((prev) => ({
                      ...prev,
                      addDescription,
                    }));
                  }}
                />
                <InputGroup.Text
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    const addDes = planConfig['addDescription'];
                    addDes.splice(index, 1);
                    setPlanConfig((prev) => ({
                      ...prev,
                      addDescription: addDes,
                    }));
                  }}
                >
                  <i
                    className="feather icon-trash"
                    style={{
                      fontSize: '14px',
                    }}
                  />
                </InputGroup.Text>
              </InputGroup>
            ))}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className={`btn btn-primary ${loading && 'is-loading'}`}
            disabled={loading}
          >
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

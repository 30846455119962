import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { delay, firebaseError } from 'utils';
import { doc, collection, getDocs, query, where } from 'firebase/firestore';
import { getStorage, ref, deleteObject, uploadBytes } from 'firebase/storage';
import { db } from 'firebase.js';
import { stripe } from 'stripe.js';

export const PLAN_FETCH_DATA_INIT = createAction('PLAN_FETCH_DATA_INIT');
export const PLAN_FETCH_DATA_SUCCESS = createAction('PLAN_FETCH_DATA_SUCCESS');
export const PLAN_FETCH_DATA_FAIL = createAction('PLAN_FETCH_DATA_FAIL');

export const PLAN_DELETE_INIT = createAction('PLAN_DELETE_INIT');
export const PLAN_DELETE_SUCCESS = createAction('PLAN_DELETE_SUCCESS');
export const PLAN_DELETE_FAIL = createAction('PLAN_DELETE_FAIL');

export const PLAN_CLEAR_DATA = createAction('PLAN_CLEAR_DATA');

export const PLAN_CREATE_INIT = createAction('PLAN_CREATE_INIT');
export const PLAN_CREATE_SUCCESS = createAction('PLAN_CREATE_SUCCESS');
export const PLAN_CREATE_FAIL = createAction('PLAN_CREATE_FAIL');

export const PLAN_MODIFY_INIT = createAction('PLAN_MODIFY_INIT');
export const PLAN_MODIFY_SUCCESS = createAction('PLAN_MODIFY_SUCCESS');
export const PLAN_MODIFY_FAIL = createAction('PLAN_MODIFY_FAIL');

export const PLAN_CLEAN_UP = createAction('PLAN_CLEAN_UP');

export const PLAN_CLEAR_DATA_LOGOUT = createAction('PLAN_CLEAR_DATA_LOGOUT');

export const fetchPlans = (all = false) => {
  return async (dispatch) => {
    dispatch(PLAN_FETCH_DATA_INIT());

    const plans = [];

    try {
      let querySnapshot;
      const ref = collection(db, 'plans');
      let q;
      if (all) {
        q = query(ref);
      } else {
        q = query(collection(db, 'plans'), where('active', '==', true));
      }
      querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        plans.push({
          id: doc.id,
          credit: data.stripe_metadata_credit ? data.stripe_metadata_credit : 0,
          price: data.stripe_metadata_price ? data.stripe_metadata_price : 0,
          name: data.name,
          description: data.description,
          addDescription:
            data.stripe_metadata_addDescription &&
            JSON.parse(data.stripe_metadata_addDescription),
          active: data.active,
          role: data.role,
        });
      });
      return dispatch(PLAN_FETCH_DATA_SUCCESS({ plans }));
    } catch (error) {
      toastr.error('', error);
      return dispatch(PLAN_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deletePlan = (id) => {
  return async (dispatch, getState) => {
    dispatch(PLAN_DELETE_INIT());
    const { locale } = getState().preferences;
    const deletePlanTask = await stripe.products.del(id);

    try {
      await Promise.all([deletePlanTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        PLAN_DELETE_FAIL({
          error: errorMessage,
        }),
      );
    }

    toastr.success('', 'The plan was deleted.');
    return dispatch(PLAN_DELETE_SUCCESS({ id }));
  };
};

export const clearPlansData = () => {
  return (dispatch) => {
    dispatch(PLAN_CLEAR_DATA());
  };
};

export const clearPlansDataLogout = () => {
  return (dispatch) => {
    dispatch(PLAN_CLEAR_DATA_LOGOUT());
  };
};

export const createPlan = (data) => {
  return async (dispatch, getState) => {
    dispatch(PLAN_CREATE_INIT());
    const { locale } = getState().preferences;
    const plan = {
      ...data,
    };

    try {
      const product = await stripe.products.create({
        name: data.name,
        description: data.description,
        metadata: {
          credit: Number(data.credit),
          price: Number(data.price),
          addDescription: JSON.stringify(data.addDescription),
        },
        default_price_data: {
          unit_amount: Number(data.price * 100),
          currency: 'sgd',
        },
        expand: ['default_price'],
        active: plan.active,
      });
      const priceId = product.default_price.id.toString();
      await stripe.prices.update(priceId, {
        metadata: {
          credit: data.credit,
        },
      });

      await delay(5000);
      plan.id = product.id;
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        PLAN_CREATE_FAIL({
          error: errorMessage,
        }),
      );
    }
    toastr.success('', 'Plan created successfully');
    return dispatch(PLAN_CREATE_SUCCESS(plan));
  };
};

export const modifyPlan = (data) => {
  return async (dispatch, getState) => {
    dispatch(PLAN_MODIFY_INIT());
    const { locale } = getState().preferences;

    const planData = {
      ...data,
    };

    const product = await stripe.products.update(data.id, {
      name: data.name,
      description: data.description,
      metadata: {
        credit: Number(data.credit),
        addDescription: JSON.stringify(data.addDescription),
      },
      active: data.active,
    });

    const priceId = product.default_price.toString();

    await stripe.prices.update(priceId, {
      metadata: {
        credit: data.credit,
      },
    });

    try {
      await Promise.all([product]);
      await delay(5000);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        PLAN_MODIFY_FAIL({
          error: errorMessage,
        }),
      );
    }
    toastr.success('', 'Package updated successfully');

    return dispatch(PLAN_MODIFY_SUCCESS(planData));
  };
};

export const plansCleanUp = () => (dispatch) => dispatch(PLAN_CLEAN_UP());

import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import { doc, collection, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import {
  getAuth,
  sendSignInLinkToEmail
} from 'firebase/auth';

import { db } from 'firebase.js';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CLEAR_DATA = createAction('USERS_CLEAR_DATA');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

const storage = getStorage();
const auth = getAuth();
const functions = getFunctions();

export const fetchUsers = () => async (dispatch) => {
  dispatch(checkUserData());

  dispatch(USERS_FETCH_DATA_INIT());

  const usersData = [];
  try {
    const querySnapshot = await getDocs(collection(db, 'users'));
    querySnapshot.forEach((doc) => {
      usersData.push({
        id: doc.id,
        ...doc.data()
      });
    });
    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        users: usersData
      })
    );
  } catch (error) {
    toastr.error('', error);
    return dispatch(USERS_FETCH_DATA_FAIL({ error }));
  }

};

export const deleteUser = id => async (dispatch, getState) => {
  dispatch(USERS_DELETE_USER_INIT());
  const { locale } = getState().preferences;

  try {
    await deleteDoc(doc(db, 'users', id));
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      USERS_DELETE_USER_FAIL({
        error: errorMessage
      })
    );
  }

  toastr.success('', 'The user was deleted.');
  return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
};

export const clearUsersData = () => dispatch => {
  dispatch(USERS_CLEAR_DATA());
};

export const clearUsersDataLogout = () => dispatch => {
  dispatch(USERS_CLEAR_DATA_LOGOUT());
};

export const createUser = ({
                             name,
                             email,
                             isAdmin
                           }) => async (dispatch, getState) => {
  dispatch(USERS_CREATE_USER_INIT());
  const { locale } = getState().preferences;

  let response;
  try {
    const createUserAuth = httpsCallable(functions, 'createUserAdmin');
    response = await createUserAuth({ email, isAdmin, name });
    console.log(response);
  } catch (error) {
    console.log(error);
    const errorMessage = firebaseError(error.message, locale);
    toastr.error('', errorMessage);
    return dispatch(
      USERS_CREATE_USER_FAIL({
        error: errorMessage
      })
    );
  }
  const actionCodeSettings = {
    url: process.env.REACT_APP_ADMIN_LOGIN_PAGE_URL,
    handleCodeInApp: true
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  } catch (error) {
    console.log(error);
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      USERS_CREATE_USER_FAIL({
        error: errorMessage
      })
    );
  }

  toastr.success('', 'User is created successfully');
  return dispatch(USERS_CREATE_USER_SUCCESS({ user: response.data }));
};

export const modifyUser = ({
                             name,
                             isAdmin,
                             id,
                             email,
                             isEditing,
                             isProfile,
                             getBookingEmail,
                           }) => async (dispatch, getState) => {
  dispatch(USERS_MODIFY_USER_INIT());
  const { locale } = getState().preferences;

  const userData = {
    id,
    name,
    email,
    isAdmin,
    getBookingEmail
  };

  const { uid } = auth.currentUser;

  if (id === uid) {
    dispatch(AUTH_UPDATE_USER_DATA({ ...userData, id }));
  }

  try {
    await updateDoc(doc(db, 'users', id), userData);
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      USERS_MODIFY_USER_FAIL({
        error: errorMessage
      })
    );
  }

  if (isProfile) {
    toastr.success('', 'Profile is updated successfully');
  } else if (isEditing) {
    toastr.success('', 'User is updated successfully');
  }

  return dispatch(USERS_MODIFY_USER_SUCCESS({ user: { ...userData, id } }));
};

export const usersCleanUp = () => dispatch => dispatch(USERS_CLEAN_UP());

import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';
import { contactOptions } from 'components/options';
import { useFormatDate } from 'hooks';
import { sortDate, sortDate2 } from 'utils';
import { Timestamp } from 'firebase/firestore';
import { getOrders } from 'lib/order';

export default function Orders () {
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const { isAdmin } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );
  const fetchOrder = async () => {
    setLoading(true);
    const result = await getOrders();
    setLoading(false);
    setOrderList(result);
  };
  useEffect(() => {
    if (isAdmin) {
      fetchOrder();
    }
  }, [isAdmin]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT}/>;

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({ row }) => (
        <p
          style={{
            width: '100px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          {row.original.id}
        </p>
      )
    },
    {
      Header: 'Customer Name',
      accessor: 'customer_name'
    },
    {
      Header: 'Email',
      accessor: 'customer_email'
    },
    {
      Header: 'Subscription',
      Cell: ({ row }) => (
        <p
          style={{
            width: '100px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          {row.original.subscription}
        </p>
      )
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: ({ row }) =>
        row.original.total < 0 ? (
          <p style={{ whiteSpace: 'nowrap' }}>
            -S${Math.abs(row.original.total)}
          </p>
        ) : (
          <p style={{ whiteSpace: 'nowrap' }}>S${row.original.total}</p>
        )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => row.original.status.toUpperCase()
    },
    {
      Header: 'Created At',
      accessor: 'created',
      sortType: sortDate2,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.created &&
          useFormatDate(row.original.created * 1000, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },
    {
      Header: 'Invoice Link',
      accessor: 'hosted_invoice_url',
      Cell: ({ row }) => (
        <a href={row.original.hosted_invoice_url} target="_blank">
          View
        </a>
      )
    }
  ];

  const data = search
    ? orderList.filter((el) => {
      const clonedElem = {
        customer_email: el.customer_email,
        customer_name: el.customer_name,
        subscription: el.subscription,
        id: el.id,
        status: el.status
      };
      return Object.values(clonedElem).some((field) =>
          field && field.toString().toLowerCase().includes(search.toLowerCase())
      );
    })
    : orderList;
  console.log(orderList);
  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage Orders </Card.Title>
            <NavSearch handleChange={setSearch}/>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? (
              <ClipLoader/>
            ) : (
              <Table
                columns={columns}
                data={data}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

import { createReducer } from 'redux-act';

import {
  VOUCHER_FETCH_DATA_INIT,
  VOUCHER_FETCH_DATA_SUCCESS,
  VOUCHER_FETCH_DATA_FAIL,
  VOUCHER_DELETE_INIT,
  VOUCHER_DELETE_SUCCESS,
  VOUCHER_DELETE_FAIL,
  VOUCHER_CLEAR_DATA,
  VOUCHER_CREATE_INIT,
  VOUCHER_CREATE_SUCCESS,
  VOUCHER_CREATE_FAIL,
  VOUCHER_MODIFY_INIT,
  VOUCHER_MODIFY_SUCCESS,
  VOUCHER_MODIFY_FAIL,
  VOUCHER_CLEAN_UP,
  VOUCHER_CLEAR_DATA_LOGOUT
} from '../../actions/vouchers';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const vouchersReducer = createReducer(
  {
    [VOUCHER_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [VOUCHER_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.vouchers,
      loading: false,
      error: null
    }),
    [VOUCHER_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [VOUCHER_DELETE_INIT]: state => ({
      ...state,
      loading: true
    }),
    [VOUCHER_DELETE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(elem => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true
    }),
    [VOUCHER_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [VOUCHER_CLEAR_DATA]: state => ({
      ...initialState,
      data: state.data
    }),
    [VOUCHER_CREATE_INIT]: state => ({
      ...state,
      loading: true
    }),
    [VOUCHER_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload),
      loading: false,
      error: null,
      success: true
    }),
    [VOUCHER_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [VOUCHER_MODIFY_INIT]: state => ({
      ...state,
      loading: true
    }),
    [VOUCHER_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.id === payload.id) {
          return payload;
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true
    }),
    [VOUCHER_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [VOUCHER_CLEAN_UP]: state => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false
    }),
    [VOUCHER_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState
    })
  },
  initialState
);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import {
  fetchUsers,
  deleteUser,
  clearUsersData,
} from 'state/actions/customers';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from '../../components/Layout/NavSearch';
import { sortDate } from '../../utils';
import { Timestamp } from '@firebase/firestore';

const Customers = () => {
  const { usersList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      usersList: state.customers.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.customers.error,
      loading: state.customers.loading,
      deleted: state.customers.deleted,
    }),
    shallowEqual,
  );

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchUsers(true));
    }

    return () => dispatch(clearUsersData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteUser(deleteModal.userId));
  };

  const columns = [
    {
      Header: '',
      accessor: 'logoUrl',
      Cell: ({ row }) => (
        <div className="image">
          <img
            src={row.original.photoURL}
            alt=""
            style={{ width: '40px' }}
            className="rounded-circle"
          />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: useFormatMessage('Users.email'),
      accessor: 'email',
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ row }) => (row.original.active ? 'Yes' : 'No'),
    },
    {
      Header: 'Credits',
      accessor: 'credit',
    },
    {
      Header: 'Phone',
      accessor: 'phoneNumber',
      Cell: ({ row }) => (
        <p style={{ whiteSpace: 'nowrap' }}>
          {row.original.phoneNumber} &nbsp;
          {row.original.isVerifiedPhone && <i className="feather icon-check-circle"/>}
        </p>
      ),
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: 'Modified At',
      accessor: 'modifiedAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.modifiedAt instanceof Timestamp &&
            useFormatDate(row.original.modifiedAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="button-actions is-right">
              <Link
                to={`/customers/${row.original.id}`}
                className="btn btn-sm btn-primary"
              >
                <i className="feather icon-edit" />
              </Link>

              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
              >
                <i className="feather icon-trash" />
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? usersList.filter((el) => {
        const clonedElem = {
          firstName: el.firstName,
          lastName: el.lastName,
          email: el.email,
          phoneNumber: el.phoneNumber || '',
          id: el.id,
        };
        if (!clonedElem.name) {
          delete clonedElem.name;
        }
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : usersList;
  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage Customers</Card.Title>
            <NavSearch handleChange={setSearch} />
            <Link to={paths.ADD_CUSTOMER} className="btn btn-primary ms-auto">
              Add new Customer
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Customers;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from 'components/Layout/NavSearch';
import {
  fetchBookings,
  deleteBooking,
  clearBookingsData,
} from '../../state/actions/bookings';
import { sortDate } from '../../utils';
import { Timestamp } from '@firebase/firestore';
import { fetchSellers } from 'state/actions/sellers';
import { fetchClasses } from 'state/actions/classes';
import { fetchUsers } from 'state/actions/customers';

const Bookings = () => {
  const {
    bookingList,
    sellers,
    customers,
    classes,
    isAdmin,
    error,
    loading,
    deleted,
  } = useSelector(
    (state) => ({
      bookingList: state.bookings.data,
      sellers: state.sellers.data,
      customers: state.customers.data,
      classes: state.classes.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.bookings.error,
      loading: state.bookings.loading,
      deleted: state.bookings.deleted,
    }),
    shallowEqual,
  );
  const [deleteModal, setDeleteModal] = useState({
    bookingId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchBookings(true));
    }

    return () => dispatch(clearBookingsData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        bookingId: null,
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (bookingId) => {
    setDeleteModal((prevState) => ({
      bookingId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ bookingId: null, isOpen: false });
  };

  const onDeleteLevelHandler = () => {
    dispatch(deleteBooking(deleteModal.bookingId));
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Customer',
      accessor: 'customerName',
    },
    {
      Header: 'Customer Email',
      accessor: 'customerEmail',
    },
    {
      Header: 'Class',
      accessor: 'className',
    },
    {
      Header: 'Credits',
      accessor: 'credit',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: 'Modified At',
      accessor: 'modifiedAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.modifiedAt instanceof Timestamp &&
            useFormatDate(row.original.modifiedAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="button-actions is-right">
          <Link
            to={`/bookings/${row.original.id}`}
            className="btn btn-sm btn-primary"
          >
            <i className="feather icon-edit" />
          </Link>

          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <i className="feather icon-trash" />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? bookingList.filter((el) => {
        const clonedElem = {
          id: el.id,
          status: el.status,
          customerEmail: el.customerEmail,
          customerName: el.customerName,
          className: el.className,
        };
        return Object.values(clonedElem).some((field) =>
          field && field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : bookingList;

  const deleteMessage = useFormatMessage('Levels.delete');

  const confirmMessage = useFormatMessage('Levels.confirm');

  const permDeleteMessage = useFormatMessage('Levels.permDelete');

  const cancelMessage = useFormatMessage('Levels.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteLevelHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage bookings </Card.Title>
            <NavSearch handleChange={setSearch} />
            <Link to={paths.ADD_BOOKING} className="btn btn-primary ms-auto">
              Add new Booking
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Bookings;

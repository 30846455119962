import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from 'components/Layout/NavSearch';
import {
  fetchClasses,
  deleteClass,
  clearClassesData,
} from '../../state/actions/classes';
import { sortDate } from '../../utils';
import { Timestamp } from '@firebase/firestore';

const Classes = () => {
  const { classList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      classList: state.classes.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.classes.error,
      loading: state.classes.loading,
      deleted: state.classes.deleted,
    }),
    shallowEqual,
  );
  const [deleteModal, setDeleteModal] = useState({
    classId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchClasses(true));
    }

    return () => dispatch(clearClassesData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        classId: null,
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (classId) => {
    setDeleteModal((prevState) => ({
      classId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ classId: null, isOpen: false });
  };

  const onDeleteLevelHandler = () => {
    dispatch(deleteClass(deleteModal.classId));
  };

  const columns = [
    {
      Header: '',
      accessor: 'photoURL',
      Cell: ({ row }) => (
        <div className="image">
          <img src={row.original.photoURL} alt="" style={{ width: '40px' }} />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: useFormatMessage('Levels.name'),
      accessor: 'name',
    },
    {
      Header: 'Seller name',
      accessor: 'sellerName',
    },
    {
      Header: 'Category',
      accessor: 'categoryName',
    },
    {
      Header: 'Level',
      accessor: 'levelName',
    },
    {
      Header: 'Age Category',
      accessor: 'ageCategoryName',
    },
    {
      Header: 'Class Type',
      accessor: 'classTypeName',
    },
    {
      Header: 'Location',
      accessor: 'location',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.location ? row.original.location.join(', ') : ''}
        </small>
      ),
    },
    {
      Header: 'Coach name',
      accessor: 'coach',
    },
    {
      Header: 'Credits',
      accessor: 'credit',
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ row }) => (row.original.active ? 'Yes' : 'No'),
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: 'Modified At',
      accessor: 'modifiedAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.modifiedAt instanceof Timestamp &&
            useFormatDate(row.original.modifiedAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="button-actions is-right">
          <Link
            to={`/classes/${row.original.id}`}
            className="btn btn-sm btn-primary"
          >
            <i className="feather icon-edit" />
          </Link>

          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <i className="feather icon-trash" />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? classList.filter((el) => {
        const clonedElem = {
          name: el.name,
          sellerName: el.sellerName || '',
          category: el.categoryName || '',
          level: el.levelName || '',
          ageCategory: el.ageCategoryName || '',
          classType: el.classTypeName || '',
          location: el.location || '',
          coach: el.coach || '',
        };
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : classList;

  const deleteMessage = useFormatMessage('Levels.delete');

  const confirmMessage = useFormatMessage('Levels.confirm');

  const permDeleteMessage = useFormatMessage('Levels.permDelete');

  const cancelMessage = useFormatMessage('Levels.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteLevelHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage classes </Card.Title>
            <NavSearch handleChange={setSearch} />
            <Link to={paths.ADD_CLASS} className="btn btn-primary ms-auto">
              Add new Class
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Classes;


import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {withRouter} from 'utils';

import NavIcon from '../NavIcon';
import NavBadge from '../NavBadge';
import NavItem from '../NavItem';
// eslint-disable-next-line import/no-self-import
import LoopNavCollapse from './index';
import { collapseToggle, navCollapseLeave } from '../../../../../state/actions/preferences';

function NavCollapse ({ collapse, type }) {
  const { layout, isOpen, isTrigger } = useSelector(
    state => ({
      layout: state.preferences.layout,
      isOpen: state.preferences.isOpen,
      isTrigger: state.preferences.isTrigger
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onCollapseToggle = (id, type) => {
    dispatch(collapseToggle({ menu: { id, type } }));
  };
  const onNavCollapseLeave = (id, type) => {
    dispatch(navCollapseLeave({ menu: { id, type } }));
  };
  useEffect(() => {
    const currentIndex = ((document.location.pathname).toString().split('/')).findIndex(id => id === collapse.id);
    if (currentIndex > -1) {
      onCollapseToggle(collapse.id, type);
    }
  });

  let navItems = '';
  if (collapse.children) {
    const collapses = collapse.children;
    navItems = Object.keys(collapses).map(item => {
      item = collapses[item];
      switch (item.type) {
        case 'collapse':
          return <LoopNavCollapse key={item.id} collapse={item} type="sub"/>;
        case 'item':
          return <NavItem layout={layout} key={item.id} item={item}/>;
        default:
          return false;
      }
    });
  }

  let itemTitle = collapse.title;
  if (collapse.icon) {
    itemTitle = <span className="pcoded-mtext">{collapse.title}</span>;
  }

  let navLinkClass = ['nav-link'];

  let navItemClass = ['nav-item', 'pcoded-hasmenu'];
  const openIndex = isOpen.findIndex(id => id === collapse.id);
  if (openIndex > -1) {
    navItemClass = [...navItemClass, 'active'];
    if (layout !== 'horizontal') {
      navLinkClass = [...navLinkClass, 'active'];
    }
  }

  const triggerIndex = isTrigger.findIndex(id => id === collapse.id);
  if (triggerIndex > -1) {
    navItemClass = [...navItemClass, 'pcoded-trigger'];
  }

  const currentIndex = ((document.location.pathname).toString().split('/')).findIndex(id => id === collapse.id);
  if (currentIndex > -1) {
    navItemClass = [...navItemClass, 'active'];
    if (layout !== 'horizontal') {
      navLinkClass = [...navLinkClass, 'active'];
    }
  }

  const subContent = (
    <>
      <a href='#' className={navLinkClass.join(' ')} onClick={() => onCollapseToggle(collapse.id, type)}>
        <NavIcon items={collapse}/>
        {itemTitle}
        <NavBadge layout={layout} items={collapse}/>
      </a>
      <ul className="pcoded-submenu">
        {navItems}
      </ul>
   </>
  );
  let mainContent = '';
  if (layout === 'horizontal') {
    mainContent = (
      <li className={navItemClass.join(' ')} onMouseLeave={() => onNavCollapseLeave(collapse.id, type)}
          onMouseEnter={() => onCollapseToggle(collapse.id, type)}>
        {subContent}
      </li>
    );
  } else {
    mainContent = (
      <li className={navItemClass.join(' ')}>
        {subContent}
      </li>
    );
  }

  return (
    <>
      {mainContent}
   </>
  );
};
export default withRouter(NavCollapse);

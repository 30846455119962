import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab, Form, Alert } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { createVoucher, modifyVoucher } from '../../state/actions/vouchers';
import paths from '../Router/paths';
import {
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  onSnapshot,
} from '@firebase/firestore';
import { db } from 'firebase.js';
import VoucherForm from 'components/VoucherForm';
import PromotionModal from 'components/PromotionModal';
import Table from 'components/Table';
import { createPromotion, updatePromotion } from 'lib/promotion';

const Voucher = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    (state) => ({
      success: state.vouchers.success,
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );
  const [loading, setLoading] = useState(false);
  const [voucher, setVoucher] = useState({});
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [promotions, setPromotions] = useState([]);
  useEffect(() => {
    const fetchVoucherData = async () => {
      const voucherRef = doc(db, 'vouchers', id);
      const response = await getDoc(voucherRef);
      return response.data();
    };

    const fetchPromotionData = async () => {
      const q = query(collection(db, `vouchers/${id}/promotions`));
      onSnapshot(q, async (querySnapshot) => {
        const data = [];
        querySnapshot.docs.map(async (doc) => {
          const docData = doc.data();
          data.push({
            ...docData,
          });
        }),
          setPromotions(data);
      });
    };

    if (id) {
      fetchVoucherData()
        .then((voucherData) => {
          setVoucher({
            ...voucherData,
            createdAt: voucherData.createdAt,
            id,
          });
        })
        .catch(() => {
          setVoucher({ error: true });
        });
      fetchPromotionData();
    }
  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Navigate to={paths.ROOT} />;
  // const redirect = (voucher.error || success) && (
  //   <Navigate to={paths.MANAGE_VOUCHER} />
  // );
  const voucherForm =
    !voucher.name && id ? (
      <ClipLoader />
    ) : (
      <VoucherForm
        voucherData={voucher}
        action={isEditing ? modifyVoucher : createVoucher}
      />
    );

  const onOpenModal = (data) => {
    setSelectedPromotion(data || undefined);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setSelectedPromotion(undefined);
  };

  const handlePromotionSubmit = async (data) => {
    try {
      setLoading(true);
      if (data?.id) {
        await updatePromotion(data);
      } else {
        // Create New
        await createPromotion(voucher.id, data);
      }
      onCloseModal();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: 'Promotion Code',
      accessor: 'code',
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ row }) => (row.original.active ? 'Yes' : 'No'),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="button-actions is-right">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => onOpenModal(row.original)}
          >
            <i className="feather icon-edit" />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <>
      {redirectAdmin}
      {/* {redirect} */}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">
                {isEditing ? 'Voucher Detail' : 'Add Voucher'}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {voucherForm}

              {id && (
                <div className="mt-4">
                  <Form.Label htmlFor="type">Promotion Code</Form.Label>
                  {promotions.length === 0 && (
                    <Alert variant="danger">
                      Please add promo code into this Voucher Rule
                    </Alert>
                  )}
                  <Card>
                    <Card.Header>
                      <Alert variant="info">
                        The promo code should be unique in this rule and it
                        should not be the same as existing promo code in Stripe.
                      </Alert>
                      <div className="d-flex flex-row align-items-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => onOpenModal()}
                        >
                          Add a promotion code
                        </button>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="b-table">
                        <Table columns={columns} data={promotions} />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <PromotionModal
        show={openModal}
        onClose={onCloseModal}
        selectedPromotion={selectedPromotion}
        onSubmit={handlePromotionSubmit}
        loading={loading}
      />
    </>
  );
};

export default Voucher;

import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from 'firebase.js';
import { getCustomers } from './customer';
import { useFormatDate } from 'hooks';
import dayjs from 'dayjs';
const Rate = {
  1: 'Very poor',
  2: 'Poor',
  3: 'Fair',
  4: 'Good',
  5: 'Very good',
};
export const getFeedBacks = async (setData, setLoading) => {
  try {
    const q = query(collection(db, 'feedBacks'), orderBy('createdAt', 'desc'));
    onSnapshot(q, async (querySnapshot) => {
      const data = [];
      setLoading(true);
      querySnapshot.docs.map(async (doc) => {
        const docData = doc.data();
        docData.q1 = Rate[docData.q1];
        docData.q2 = Rate[docData.q2];
        docData.q3 = Rate[docData.q3];
        docData.formatCreatedAt = dayjs(docData.createdAt.toDate()).format(
          'DD/MM/YYYY',
        );

        docData.customerData.createdAt = dayjs(
          new Date(docData.customerData.createdAt),
        ).format('DD/MM/YYYY');

        data.push({
          ...docData,
          id: doc.id,
        });
      }),
        setData(data);
      setLoading(false);
    });
  } catch (err) {
    console.error(err);
    setLoading(false);
    setData([]);
  }
};

import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { convertTimestampToFBTimeStamp, firebaseError } from 'utils';
import {
  doc,
  collection,
  getDocs,
  getDoc,
  deleteDoc,
  query,
  where,
  updateDoc,
  serverTimestamp,
  orderBy,
  Timestamp
} from 'firebase/firestore';
import { getStorage, ref, deleteObject, uploadBytes } from 'firebase/storage';
import { db } from 'firebase.js';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();

export const BOOKING_FETCH_DATA_INIT = createAction('BOOKING_FETCH_DATA_INIT');
export const BOOKING_FETCH_DATA_SUCCESS = createAction(
  'BOOKING_FETCH_DATA_SUCCESS'
);
export const BOOKING_FETCH_DATA_FAIL = createAction('BOOKING_FETCH_DATA_FAIL');

export const BOOKING_DELETE_INIT = createAction('BOOKING_DELETE_INIT');
export const BOOKING_DELETE_SUCCESS = createAction(
  'BOOKING_DELETE_SUCCESS'
);
export const BOOKING_DELETE_FAIL = createAction('BOOKING_DELETE_FAIL');

export const BOOKING_CLEAR_DATA = createAction('BOOKING_CLEAR_DATA');

export const BOOKING_CREATE_INIT = createAction('BOOKING_CREATE_INIT');
export const BOOKING_CREATE_SUCCESS = createAction(
  'BOOKING_CREATE_SUCCESS'
);
export const BOOKING_CREATE_FAIL = createAction('BOOKING_CREATE_FAIL');

export const BOOKING_MODIFY_INIT = createAction('BOOKING_MODIFY_INIT');
export const BOOKING_MODIFY_SUCCESS = createAction(
  'BOOKING_MODIFY_SUCCESS'
);
export const BOOKING_MODIFY_FAIL = createAction('BOOKING_MODIFY_FAIL');

export const BOOKING_CLEAN_UP = createAction('BOOKING_CLEAN_UP');

export const BOOKING_CLEAR_DATA_LOGOUT = createAction('BOOKING_CLEAR_DATA_LOGOUT');

export const fetchBookings = (all = false) => {
  return async (dispatch) => {

    dispatch(BOOKING_FETCH_DATA_INIT());

    const bookings = [];

    try {
      let querySnapshot;
      let q;
      const ref = collection(db, 'bookings');
      if (all) {
        q = query(ref, orderBy('createdAt', 'desc'));
      } else {
        q = query(collection(db, 'bookings'), where('active', '==', true),
          orderBy('createdAt', 'desc')
        );
      }
      querySnapshot = await getDocs(q);

      const q2 = query(collection(db, 'customers'));
      const snap2 = await getDocs(q2);
      const q3 = query(collection(db, 'classes'));
      const snap3 = await getDocs(q3);

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const customer = snap2.docs.filter((item) => item.id == docData.customerId);
        const classItem = snap3.docs.filter((item) => item.id == docData.classId);
        bookings.push({
          id: doc.id,
          customerEmail: customer[0]?.get('email'),
          customerName: `${customer[0]?.get('firstName')} ${customer[0]?.get('lastName')}`,
          className: classItem[0]?.get('name'),
          ...doc.data()
        });
      });
      return dispatch(
        BOOKING_FETCH_DATA_SUCCESS({ bookings })
      );
    } catch (error) {
      toastr.error('', error);
      return dispatch(BOOKING_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deleteBooking = id => {
  return async (dispatch, getState) => {
    dispatch(BOOKING_DELETE_INIT());
    const { locale } = getState().preferences;

    const deleteBookingTask = await deleteDoc(doc(db, 'bookings', id));

    try {
      await Promise.all([deleteBookingTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        BOOKING_DELETE_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The booking was deleted.');
    return dispatch(BOOKING_DELETE_SUCCESS({ id }));
  };
};

export const clearBookingsData = () => {
  return dispatch => {
    dispatch(BOOKING_CLEAR_DATA());
  };
};

export const clearBookingsDataLogout = () => {
  return dispatch => {
    dispatch(BOOKING_CLEAR_DATA_LOGOUT());
  };
};

export const createBooking = (data) => {
  return async (dispatch, getState) => {
    dispatch(BOOKING_CREATE_INIT());
    const { locale } = getState().preferences;
    let booking;
    try {
      const addBooking = httpsCallable(functions, 'createBooking');
      booking = {
        uid: data.customerId,
        classId: data.classId,
        startDate: convertTimestampToFBTimeStamp(data.startDate),
        endDate: convertTimestampToFBTimeStamp(data.endDate)
      };
      const response = await addBooking(booking);
      booking.id = response.id;
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        BOOKING_CREATE_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Booking created successfully');
    return dispatch(BOOKING_CREATE_SUCCESS(booking));
  };
};

export const modifyBooking = (data) => {
  return async (dispatch, getState) => {
    dispatch(BOOKING_MODIFY_INIT());
    const { locale } = getState().preferences;

    const bookingData = {
      ...data,
      modifiedAt: serverTimestamp()
    };
    bookingData.startDate = convertTimestampToFBTimeStamp(data.startDate);
    bookingData.endDate = convertTimestampToFBTimeStamp(data.endDate);
    delete bookingData.file;
    const ref = doc(db, 'bookings', data.id);
    const booking = await getDoc(ref);
    if (booking.exists()) {
      const before = booking.data();

      if (before.status !== 'Pending' && bookingData.status === 'Pending' ||
        before.status === 'Rejected' && bookingData.status === 'Confirmed'
      ) {
        const error = 'Can not update the status, please create new booking';
        toastr.error('', error);
        return dispatch(
          BOOKING_MODIFY_FAIL({
            error
          })
        );
      }
    }
    const updateBookingDbTask = await updateDoc(doc(db, 'bookings', data.id), bookingData);
    try {
      await Promise.all([updateBookingDbTask]);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        BOOKING_MODIFY_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Booking updated successfully');

    return dispatch(BOOKING_MODIFY_SUCCESS(bookingData));
  };
};

export const bookingsCleanUp = () => dispatch => dispatch(BOOKING_CLEAN_UP());

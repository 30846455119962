import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';
import { getFeedBacks } from 'lib/feedback';
import { feedBackOptions } from 'components/options';
import { useFormatDate } from 'hooks';
import { sortDate } from 'utils';
import { Timestamp } from 'firebase/firestore';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
const Rate = {
  1: 'Very poor',
  2: 'Poor',
  3: 'Fair',
  4: 'Good',
  5: 'Very good',
};

export default function FeedBacks() {
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [feedBackList, setFeedBackList] = useState([]);
  const { isAdmin } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );
  const fetchFeedBack = async () => {
    await getFeedBacks(setFeedBackList, setLoading);
  };
  useEffect(() => {
    if (isAdmin) {
      fetchFeedBack();
    }
  }, [isAdmin]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const columns = [
    {
      Header: 'Customer',
      accessor: 'customerId',
      Cell: ({ row }) => (
        <Link to={`/customers/${row.original?.customerData?.uid}`}>
          {`${row.original?.customerData?.firstName} ${row.original?.customerData?.lastName} (${row.original?.customerData?.phoneNumber})`}
        </Link>
      ),
    },
    {
      Header: 'Q1 (How would you rate your LyfeGo experience?)',
      accessor: 'q1',
    },
    {
      Header:
        'Q2 (How would you rate the convenience of the class booking process?)',
      accessor: 'q2',
    },
    {
      Header: 'Q3 (How would you rate the quality of the classes?)',
      accessor: 'q3',
    },
    {
      Header: 'Q4 (What is your reason for canceling your LyfeGo membership?)',
      accessor: 'q4',
    },
    {
      Header: 'Q5 (Would you recommend LyfeGo to your friend?)',
      accessor: 'q5',
    },
    {
      Header: 'Signup At',
      accessor: 'customerData.createdAt',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
  ];

  const data = search
    ? feedBackList.filter((el) => {
        const clonedElem = {};
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : feedBackList;

  const headers = [
    { label: 'First Name', key: 'customerData.firstName' },
    { label: 'Last Name', key: 'customerData.lastName' },
    { label: 'Phone Number', key: 'customerData.phoneNumber' },
    { label: 'Q1', key: 'q1' },
    { label: 'Q2', key: 'q2' },
    { label: 'Q3', key: 'q3' },
    { label: 'Q4', key: 'q4' },
    { label: 'Q5', key: 'q5' },
    { label: 'Signup At', key: 'customerData.createdAt' },
    { label: 'Date', key: 'formatCreatedAt' },
  ];

  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">FeedBacks</Card.Title>
            <CSVLink
              data={data}
              headers={headers}
              filename={`${dayjs(Date.now()).format('YYYYMMDD-HH-mm')}.csv`}
              className="btn btn-primary ms-auto"
            >
              Export
            </CSVLink>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? (
              <ClipLoader />
            ) : (
              <Table
                columns={columns}
                data={data}
                style={{
                  overflowX: 'auto',
                  width: '100%',
                  display: 'block',
                }}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import {
  doc,
  collection,
  getDocs,
  deleteDoc,
  addDoc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import { db } from 'firebase.js';

export const CLASS_CATEGORY_FETCH_DATA_INIT = createAction(
  'CLASS_CATEGORY_FETCH_DATA_INIT',
);
export const CLASS_CATEGORY_FETCH_DATA_SUCCESS = createAction(
  'CLASS_CATEGORY_FETCH_DATA_SUCCESS',
);
export const CLASS_CATEGORY_FETCH_DATA_FAIL = createAction(
  'CLASS_CATEGORY_FETCH_DATA_FAIL',
);

export const CLASS_CATEGORY_DELETE_INIT = createAction(
  'CLASS_CATEGORY_DELETE_INIT',
);
export const CLASS_CATEGORY_DELETE_SUCCESS = createAction(
  'CLASS_CATEGORY_DELETE_SUCCESS',
);
export const CLASS_CATEGORY_DELETE_FAIL = createAction(
  'CLASS_CATEGORY_DELETE_FAIL',
);

export const CLASS_CATEGORY_CLEAR_DATA = createAction(
  'CLASS_CATEGORY_CLEAR_DATA',
);

export const CLASS_CATEGORY_CREATE_INIT = createAction(
  'CLASS_CATEGORY_CREATE_INIT',
);
export const CLASS_CATEGORY_CREATE_SUCCESS = createAction(
  'CLASS_CATEGORY_CREATE_SUCCESS',
);
export const CLASS_CATEGORY_CREATE_FAIL = createAction(
  'CLASS_CATEGORY_CREATE_FAIL',
);

export const CLASS_CATEGORY_MODIFY_INIT = createAction(
  'CLASS_CATEGORY_MODIFY_INIT',
);
export const CLASS_CATEGORY_MODIFY_SUCCESS = createAction(
  'CLASS_CATEGORY_MODIFY_SUCCESS',
);
export const CLASS_CATEGORY_MODIFY_FAIL = createAction(
  'CLASS_CATEGORY_MODIFY_FAIL',
);

export const CLASS_CATEGORY_CLEAN_UP = createAction('CLASS_CATEGORY_CLEAN_UP');

export const CLASS_CATEGORY_CLEAR_DATA_LOGOUT = createAction(
  'CLASS_CATEGORY_CLEAR_DATA_LOGOUT',
);

export const fetchCategories = (all = false) => {
  return async (dispatch) => {
    dispatch(CLASS_CATEGORY_FETCH_DATA_INIT());

    try {
      let querySnapshot;
      let q;
      const ref = collection(db, 'classCategories');
      if (all) {
        q = query(ref, orderBy('createdAt', 'desc'));
      } else {
        q = query(
          ref,
          where('active', '==', true),
          orderBy('createdAt', 'desc'),
        );
      }
      onSnapshot(q, async (querySnapshot) => {
        const categories = [];
        // querySnapshot = await getDocs(q);
        querySnapshot.docs.forEach((doc) => {
          categories.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return dispatch(CLASS_CATEGORY_FETCH_DATA_SUCCESS({ categories }));
      });
    } catch (error) {
      console.log(error);
      toastr.error('', error);
      return dispatch(CLASS_CATEGORY_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deleteCategory = (id) => {
  return async (dispatch, getState) => {
    dispatch(CLASS_CATEGORY_DELETE_INIT());
    const { locale } = getState().preferences;

    const deleteCategoryTask = await deleteDoc(doc(db, 'classCategories', id));

    try {
      await Promise.all([deleteCategoryTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CLASS_CATEGORY_DELETE_FAIL({
          error: errorMessage,
        }),
      );
    }

    toastr.success('', 'The category was deleted.');
    return dispatch(CLASS_CATEGORY_DELETE_SUCCESS({ id }));
  };
};

export const clearCategoriesData = () => {
  return (dispatch) => {
    dispatch(CLASS_CATEGORY_CLEAR_DATA());
  };
};

export const clearCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(CLASS_CATEGORY_CLEAR_DATA_LOGOUT());
  };
};

export const createCategory = (data) => {
  return async (dispatch, getState) => {
    dispatch(CLASS_CATEGORY_CREATE_INIT());
    const { locale } = getState().preferences;

    const category = {
      ...data,
      createdAt: serverTimestamp(),
    };
    console.log(category);

    try {
      const createCategoryDbTask = await addDoc(
        collection(db, 'classCategories'),
        category,
      );
      category.id = createCategoryDbTask.id;
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CLASS_CATEGORY_CREATE_FAIL({
          error: errorMessage,
        }),
      );
    }
    toastr.success('', 'Category created successfully');
    return dispatch(CLASS_CATEGORY_CREATE_SUCCESS(category));
  };
};

export const modifyCategory = (data) => {
  return async (dispatch, getState) => {
    dispatch(CLASS_CATEGORY_MODIFY_INIT());
    const { locale } = getState().preferences;

    const categoryData = {
      ...data,
    };
    delete categoryData.file;
    const updateCategoryDbTask = await updateDoc(
      doc(db, 'classCategories', data.id),
      categoryData,
    );

    try {
      await Promise.all([updateCategoryDbTask]);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CLASS_CATEGORY_MODIFY_FAIL({
          error: errorMessage,
        }),
      );
    }
    toastr.success('', 'Category updated successfully');

    return dispatch(CLASS_CATEGORY_MODIFY_SUCCESS(categoryData));
  };
};

export const modifySortOrderCategory = (data) => {
  return async (dispatch, getState) => {
    const { locale } = getState().preferences;

    const categoryData = {
      ...data,
    };
    delete categoryData.file;
    const updateCategoryDbTask = await updateDoc(
      doc(db, 'classCategories', data.id),
      categoryData,
    );

    try {
      await Promise.all([updateCategoryDbTask]);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CLASS_CATEGORY_MODIFY_FAIL({
          error: errorMessage,
        }),
      );
    }
  };
};

export const categoriesCleanUp = () => (dispatch) =>
  dispatch(CLASS_CATEGORY_CLEAN_UP());

import React from 'react';

import {
  Paper,
  Grid,
  CircularProgress,
  Box,
  TextField,
  IconButton
} from '@material-ui/core';

export default function ImageElement (
  {
    image,
    index,
    isFirstElement,
    isLastElement,
    handleChangeOrderUp,
    handleChangeOrderDown,
    handleDeleteImage,
    changeImageField
  }) {
  return (
    <Box my={2} width={600}>
      <Paper>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item container alignItems="center" justify="center" xs={6}>
            {image.url ? (
              <img
                src={image.url}
                alt={`Upload Preview ${index + 1}`}
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%'
                }}
              />
            ) : (
              <Box p={2}>
                <CircularProgress/>
              </Box>
            )}
          </Grid>
          <Grid item container alignItems="center" xs={4}>
            <TextField
              multiline
              size="small"
              rows={4}
              fullWidth
              variant="outlined"
              value={image.description}
              onChange={(event) => {
                changeImageField(
                  index,
                  'description',
                  event.target.value
                );
              }}
            />
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            item
            xs={2}
          >
            <Grid item container alignItems="center" justify="center">
              {!isFirstElement && (
                <IconButton
                  aria-label="Image up"
                  onClick={() => handleChangeOrderUp(index)}
                >
                  <span className="icon pr-1">
                    <i className="feather icon-arrow-up default"/>
                  </span>
                </IconButton>
              )}
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justify="center"
              xs={4}
            >
              <IconButton
                aria-label="Delete Image"
                onClick={() => handleDeleteImage(index)}
              >
                <span className="icon pr-1">
                    <i className="feather icon-trash default"/>
                  </span>
              </IconButton>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              {!isLastElement && (
                <IconButton
                  aria-label="Image down"
                  onClick={() => handleChangeOrderDown(index)}
                >
                   <span className="icon pr-1">
                    <i className="feather icon-arrow-down default"/>
                  </span>
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Layout from 'components/Layout';

import paths from '../paths';

function PrivateRoute({children}) {
  const { id } = useSelector(
    state => ({
      id: state.auth.userData.id
    }),
    shallowEqual
  );

  return id ? <Layout>{children}</Layout> : <Navigate to={paths.LOGIN} replace />;
};

export default PrivateRoute;

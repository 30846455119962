import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from 'components/Layout/NavSearch';
import { Timestamp } from 'firebase/firestore';
import {
  fetchCategories,
  deleteCategory,
  clearCategoriesData,
  modifyCategory,
  modifySortOrderCategory,
} from '../../state/actions/classCategories';
import { sortDate } from '../../utils';
import { IconButton } from '@material-ui/core';

const ClassCategories = () => {
  const { categoryList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      categoryList: state.classCategories.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.classCategories.error,
      loading: state.classCategories.loading,
      deleted: state.classCategories.deleted,
    }),
    shallowEqual,
  );
  const [deleteModal, setDeleteModal] = useState({
    catId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchCategories(true));
    }

    return () => dispatch(clearCategoriesData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        catId: null,
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (catId) => {
    setDeleteModal((prevState) => ({
      catId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ catId: null, isOpen: false });
  };

  const onDeleteLevelHandler = () => {
    dispatch(deleteCategory(deleteModal.catId));
  };

  const onUpdateSortOrder = (data, direction) => {
    if (direction === 'up') {
      data.sortOrder = (data.sortOrder || 0) + 1;
    } else {
      data.sortOrder = (data.sortOrder || 0) - 1;
    }
    dispatch(modifySortOrderCategory(data));
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },

    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ row }) => (row.original.active ? 'Yes' : 'No'),
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: 'Sort Order',
      accessor: 'sortOrder',
      Cell: ({ row }) => {
        console.log(row);
        return (
          <>
            <IconButton
              aria-label="Image up"
              onClick={() => onUpdateSortOrder(row.original, 'up')}
            >
              <span className="icon pr-1">
                <i className="feather icon-arrow-up default" />
              </span>
            </IconButton>

            {row.original.sortOrder || 0}

            <IconButton
              aria-label="Image down"
              onClick={() => onUpdateSortOrder(row.original, 'down')}
            >
              <span className="icon pr-1">
                <i className="feather icon-arrow-down default" />
              </span>
            </IconButton>
          </>
        );
      },
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="button-actions is-right">
          <Link
            to={`/class-categories/${row.original.id}`}
            className="btn btn-sm btn-primary"
          >
            <i className="feather icon-edit" />
          </Link>

          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <i className="feather icon-trash" />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? categoryList.filter((el) => {
        const clonedElem = { name: el.name };
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : categoryList;

  const deleteMessage = useFormatMessage('Levels.delete');

  const confirmMessage = useFormatMessage('Levels.confirm');

  const permDeleteMessage = useFormatMessage('Levels.permDelete');

  const cancelMessage = useFormatMessage('Levels.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteLevelHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage Class Categories </Card.Title>
            <NavSearch handleChange={setSearch} />
            <Link to={paths.ADD_CLASS_CAT} className="btn btn-primary ms-auto">
              Add new Category
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? (
              <ClipLoader />
            ) : (
              <Table
                columns={columns}
                data={data.sort(
                  (a, b) => (b.sortOrder || 0) - (a.sortOrder || 0),
                )}
              />
            )}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClassCategories;

import React from 'react';

import './Footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              © {process.env.REACT_APP_YEAR} <span> Powered by HeySheep</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import DatePickerStyled from 'components/DatePicker';
import { Timestamp } from 'firebase/firestore';
import { useChangeHandler } from 'hooks';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import { validateRating } from 'utils';

export default function PromotionModal({
  selectedPromotion,
  show,
  onClose,
  onSubmit,
  loading,
}) {
  const [promotion, setPromotion] = useState(selectedPromotion);
  const onChangeHandler = useChangeHandler(setPromotion);

  useEffect(() => {
    setPromotion({
      ...selectedPromotion,
    });
  }, [show]);

  const canSubmit = promotion?.code;

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title
          className="fw-bold"
          style={{
            color: 'black',
            fontSize: '18px',
          }}
        >
          {selectedPromotion ? 'Update' : 'Add'} Promotion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label htmlFor="name">Promotion Code *</Form.Label>
          <Form.Control
            id="name"
            type="text"
            required
            name="code"
            value={promotion?.code}
            disabled={!!selectedPromotion}
            onChange={(e) =>
              setPromotion((prev) => ({
                ...prev,
                code: e.target.value.toUpperCase(),
              }))
            }
          />
        </Form.Group>
        <br />

        <Form.Group>
          <Form.Label>Active</Form.Label>
          <Form.Check
            custom
            type="switch"
            name="active"
            id="active"
            checked={promotion?.active}
            onChange={onChangeHandler}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onSubmit(promotion);
            setPromotion();
          }}
          className={`btn btn-primary ${loading && 'is-loading'}`}
          disabled={!canSubmit || loading}
        >
          {selectedPromotion ? 'Update' : 'Add'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

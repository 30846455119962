import { collection, doc, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from 'firebase.js';
import { getClassById, getClasses } from "./class";
import { getCustomerById, getCustomers } from "./customer";
import { getSellerById } from "./seller";
import { toastr } from "react-redux-toastr";

export const getBookings = async (setBookingData, setLoading) => {
  try {
    const q = query(
      collection(db, 'bookings'),
      where('status', '==', 'Pending'),
      orderBy("createdAt", "desc"),
      limit(5)
    );
    onSnapshot(q, async (querySnapshot) => {
      setLoading(true);
      const data = [];
      const classesData = await getClasses()
      const customersData = await getCustomers()
      querySnapshot.docs.map(async (doc) => {
          const docData = doc.data();
          docData.classData = classesData.find((val) => val.id === docData.classId);
          docData.customerData = customersData.find((val) => val.id === docData.customerId)
          data.push({
            ...docData,
            id: doc.id
          });
      })
      console.log(data)
      setBookingData(data);
      setLoading(false);
    });
  } catch (err) {
    console.error(err);
    setLoading(false);
    setBookingData([]);
  }
};

export const updateStatusBookingById = async (bookingId, status) => {
  try {

    await setDoc(doc(db, 'bookings', bookingId), {
      status: status
    });

    toastr.success('', 'Booking updated successfully');
  } catch (error) {
    toastr.error('', error.message);
    
  }
}

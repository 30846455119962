import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab, Table } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import {
  createSlideShow,
  modifySlideShow,
} from '../../state/actions/slideShows';
import paths from '../Router/paths';
import { doc, getDoc } from '@firebase/firestore';
import { db } from 'firebase.js';
import SlideShowForm from 'components/SlideShowForm';

const SlideShow = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    (state) => ({
      success: state.slideShows.success,
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );

  const [slideShow, setSlideShow] = useState({});

  useEffect(() => {
    const fetchSlideShowData = async () => {
      const slideShowRef = doc(db, 'slideShows', id);
      const response = await getDoc(slideShowRef);
      return response.data();
    };

    if (id) {
      fetchSlideShowData()
        .then((slideShowData) => {
          setSlideShow({
            ...slideShowData,
            createdAt: slideShowData.createdAt,
            id,
          });
        })
        .catch(() => {
          setSlideShow({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Navigate to={paths.ROOT} />;
  const redirect = (slideShow.error || success) && (
    <Navigate to={paths.MANAGE_SLIDE} />
  );
  const slideShowForm =
    !slideShow.name && id ? (
      <ClipLoader />
    ) : (
      <SlideShowForm
        slideShowData={slideShow}
        action={isEditing ? modifySlideShow : createSlideShow}
      />
    );

  
  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">
                {isEditing ? 'Edit Slide Show' : 'Add Slide Show'}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {slideShowForm}

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SlideShow;

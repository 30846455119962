/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/customers';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { validateEmail } from 'utils';
import './style.scss';

const CreditForm = ({ isEditing, userData, action }) => {
  const { loading } = useSelector(
    state => ({
      loading: state.customers.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  const [user, setUser] = useState(userData);

  const onChangeHandler = useChangeHandler(setUser);

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...user, isEditing })
    );
  };

  let emailInput = {
    modifier: null,
    message: { modifier: null, content: null }
  };

  const invalidEmail = user.email && !validateEmail(user.email);

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  if (invalidEmail) {
    emailInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: invalidEmailMessage
      }
    };
  }

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="form-group is-horizontal">
        <Form.Label>Credit</Form.Label>
        <input
          className={`form-control`}
          type="number"
          required
          name="credit"
          value={user.credit}
          onChange={onChangeHandler}
        />
        {emailInput.message.content && (
          <p
            className={`help text-${emailInput.message.modifier}`}
          >
            {emailInput.message.content}
          </p>
        )}
      </div>
      <br/>
      <Form.Group>

        <button
          type="submit"
          className={`btn btn-primary ${loading &&
          'is-loading'}`}
        >
          <span>{useFormatMessage('UserForm.submit')}</span>
        </button>
      </Form.Group>
    </form>
  );
};

CreditForm.propTypes = {
  isEditing: PropTypes.bool,
  userData: PropTypes.shape({
    id: PropTypes.string,
    credit: PropTypes.number
  }),
  action: PropTypes.func.isRequired
};

export default CreditForm;

import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';
import { contactOptions } from 'components/options';
import { useFormatDate } from 'hooks';
import { sortDate } from 'utils';
import { Timestamp } from 'firebase/firestore';
import { getTransactions } from 'lib/transaction';

export default function Transactions() {
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [transactionList, setTransactionList] = useState([])
  const { isAdmin } = useSelector(
    state => ({
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual
  );
  const fetchTransaction = async() => {
    setLoading(true)
    const result = await getTransactions()
    setLoading(false)
    setTransactionList(result)
  }
  useEffect(() => {

    if (isAdmin) {
      fetchTransaction()
    }
  }, [isAdmin]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Customer Name',
      accessor: 'name',
    },
    {
      Header: 'Customer Email',
      accessor: 'email',
    },
    {
      Header: 'Type',
      accessor: 'type',

    },
    {
      Header: 'Description',
      accessor: 'description',

    },
    {
      Header: 'Amount',
      accessor: 'amount'
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          { row.original.createdAt instanceof Timestamp && useFormatDate(row.original.createdAt.toDate(), {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },

  ];

  const data = search
    ? transactionList.filter((el) => {
        const clonedElem = { email: el.email, name: el.name, type: el.type, id: el.id };
        return Object.values(clonedElem).some((field) =>
          field && field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : transactionList;
  console.log(transactionList);
  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage Transactions </Card.Title>
            <NavSearch handleChange={setSearch} />
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} style={{
              overflowX: 'auto',
              width: '100%',
              display: 'block'
            }}/>}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from 'firebase.js';

export const getTransactions = async () => {
  try {
    const q = query(collection(db, 'transactions'),  orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const data = [];
    const q2 = query(collection(db, 'customers'));
    const snap2 = await getDocs(q2);
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      const customerId = docData.customerId;
      const customer = snap2.docs.filter((cus) => cus.id == customerId);
      data.push({
        customerId: customerId,
        name: `${customer[0]?.get('firstName')} ${customer[0]?.get('lastName')}`,
        email: customer[0]?.get('email'),
        ...docData,
        id: doc.id
      });
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

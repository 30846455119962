import { collectionGroup,collection, getDocs,getDoc, orderBy, query } from 'firebase/firestore';
import { db } from 'firebase.js';

export const getOrders = async () => {
  try {
    const q = query(collectionGroup(db, 'invoices'), orderBy('created', 'desc'));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      console.log(docData)
      docData.total = docData.total/100
      data.push({
        ...docData,
        id: doc.id,
      });
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

import React, { useState, useEffect } from 'react';
import {
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

import { Grid, Box } from '@material-ui/core';

import ImageDropzone from './ImageDropzone';
import ImageElement from './ImageElement';
import { doc, updateDoc } from '@firebase/firestore';
import { db } from 'firebase.js';

export default function Slider({ slider, sellerId }) {
  const [imageList, setImageList] = useState([...slider]);

  const changeImageField = (index, parameter, value) => {
    const newArray = [...imageList];
    newArray[index][parameter] = value;

    setImageList(newArray);
  };
  const updateDb = async (imageList) => {
    const slider = imageList.map((x) => ({
      url: x.url,
      description: x.description,
    }));
    await updateDoc(doc(db, 'sellers', sellerId), { slider });
  };

  const handleChangeOrderUp = (index) => {
    // If first, ignore
    if (index !== 0) {
      const newArray = [...imageList];
      const intermediate = newArray[index - 1];
      newArray[index - 1] = newArray[index];
      newArray[index] = intermediate;
      setImageList(newArray);
    }
  };

  const handleChangeOrderDown = (index) => {
    // If last, ignore
    if (index < imageList.length - 1) {
      const newArray = [...imageList];
      const intermediate = newArray[index + 1];
      newArray[index + 1] = newArray[index];
      newArray[index] = intermediate;
      setImageList(newArray);
    }
  };

  const handleDeleteImage = (index) => {
    deleteObject(imageList[index].storageRef)
      .then(() => {
        const newArray = [...imageList];
        newArray.splice(index, 1);
        setImageList(newArray);
      })
      .catch((error) => {
        if (error.code === 'storage/object-not-found') {
          const newArray = [...imageList];
          newArray.splice(index, 1);
          setImageList(newArray);
        }
        console.log('Error deleting file:', error);
      });
  };

  useEffect(() => {
    imageList.forEach((image, index) => {
      if (image.status === 'FINISH' || image.status === 'UPLOADING') return;
      changeImageField(index, 'status', 'UPLOADING');
      const uploadTask = uploadBytesResumable(image.storageRef, image.file);
      uploadTask.on(
        'state_changed',
        null,
        function error(err) {
          console.log('Error Image Upload:', err);
        },
        async function complete() {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          changeImageField(index, 'url', url);
          changeImageField(index, 'status', 'FINISH');
        },
      );
    });
    if (imageList && imageList.length > 0) {
      updateDb(imageList);
    }
  });
  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Box border={1} margin={4} padding={3}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          xs={12}
          spacing={1}
        >
          <Grid item container xs={12} justify="center">
            <ImageDropzone id={sellerId} setImageList={setImageList} />
          </Grid>
        </Grid>
      </Box>
      {imageList.length > 0 && (
        <Box bgcolor="primary.light" p={4}>
          {imageList.map((image, index) => {
            return (
              <Grid item key={index}>
                <ImageElement
                  image={image}
                  index={index}
                  isFirstElement={index === 0}
                  isLastElement={index === imageList.length - 1}
                  handleChangeOrderUp={handleChangeOrderUp}
                  handleChangeOrderDown={handleChangeOrderDown}
                  handleDeleteImage={handleDeleteImage}
                  changeImageField={changeImageField}
                />
              </Grid>
            );
          })}
        </Box>
      )}
    </Grid>
  );
}

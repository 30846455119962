import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import { db, storage } from 'firebase.js';
import { toastr } from 'react-redux-toastr';

export const updatePlanConfig = async (data) => {
  try {
    await setDoc(doc(db, 'config', 'plans'), {
      ...data,
      modifiedAt: Timestamp.now(),
    });
    toastr.success('', 'Update Successfully');
  } catch (error) {
    console.log(error);
    toastr.error('', error);
  }
};

export const getPlanConfig = async () => {
  try {
    const docRef = doc(db, 'config', 'plans');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        id: docSnap.id,
      };
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

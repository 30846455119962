import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { categoriesCleanUp, clearCategoriesData } from 'state/actions/classCategories';
import { useChangeHandler, useFormatMessage } from 'hooks';

const CategoryForm = ({ categoryData, action }) => {
  const { loading } = useSelector(
    state => ({
      loading: state.classCategories.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!categoryData.id) dispatch(clearCategoriesData());
    return () => dispatch(categoriesCleanUp());
  }, [categoryData.id, dispatch]);

  const [category, setCategory] = useState(categoryData);

  const onChangeHandler = useChangeHandler(setCategory);

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...category })
    );
  };

  const canSubmit = category.name;

  return (
    <Row>
      <Col lg={12}>
        <form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Active</Form.Label>
            <Form.Check
              custom
              type="switch"
              name="active"
              id="active"
              checked={category.active}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label htmlFor="name">
              Name *
            </Form.Label>
            <Form.Control
              id="name"
              type="text"
              required
              name="name"
              value={category.name}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br/>

          <Form.Group>
            <Form.Label htmlFor="info">
              Info
            </Form.Label>
            <Form.Control
              id="info"
              as="textarea"
              name="info"
              rows={3}
              value={category.info}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br/>
          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading &&
              'is-loading'}`}
              disabled={!canSubmit}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_CLASS_CAT} className="button">
              {useFormatMessage('UserForm.goBack')}
            </Link>
          </Form.Group>
        </form>
      </Col>
    </Row>
  );
};

CategoryForm.propTypes = {
  categoryData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    active: PropTypes.boolean,
  }),
  action: PropTypes.func.isRequired
};

export default CategoryForm;

import React from 'react';
import Link from '../../Link';
import logo from 'assets/images/only-logo-white.png';

const navLogo = ({ collapseMenu, onToggleNavigation }) => {
  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <div className="navbar-brand header-logo">
      <Link to='/' className="b-brand">
        <img src={logo} alt=""/>
        <span className="b-title">LyfeGo Admin</span>
      </Link>
      <a href='#' className={toggleClass.join(' ')} id="mobile-collapse" onClick={onToggleNavigation}><span/></a>
    </div>
  );
};

export default navLogo;

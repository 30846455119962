/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/customers';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { validateEmail, validateSGPhone } from 'utils';
import './style.scss';

const CustomerForm = ({ isEditing, isProfile, userData, action }) => {
  const { loading } = useSelector(
    state => ({
      loading: state.customers.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  const [user, setUser] = useState(userData);

  const onChangeHandler = useChangeHandler(setUser);

  const onFileChangedHandler = event => {
    const file = event.target.files[0];
    setUser(prevState => ({ ...prevState, file, photoURL: null }));
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...user, isEditing, isProfile })
    );
  };

  let emailInput = {
    modifier: null,
    message: { modifier: null, content: null }
  };

  let phoneInput = {
    modifier: null,
    message: { modifier: null, content: null }
  };

  const invalidEmail = user.email && !validateEmail(user.email);

  const invalidPhone = user.phoneNumber && !validateSGPhone(user.phoneNumber)

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  if (invalidEmail) {
    emailInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: invalidEmailMessage
      }
    };
  }

  if(invalidPhone) {
    phoneInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: "Invalid Phone Number"
      }
    }
  }

  const canSubmit =
    user.firstName && user.lastName && !invalidEmail && !invalidPhone;

  const imagePreviewUrl = !user.photoURL
    ? user.file && URL.createObjectURL(user.file)
    : user.photoURL;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  const emailMessage = useFormatMessage('UserForm.email');

  return (
    <form onSubmit={onSubmitHandler}>
      <Form.Group>
        <Form.Label>Active</Form.Label>
        <Form.Check
          custom
          type="switch"
          name="active"
          id="active"
          checked={user.active}
          onChange={onChangeHandler}
        />
      </Form.Group>
      <br/>

      <div className="form-group is-horizontal">
        <Form.Label>{emailMessage}</Form.Label>
        <input
          className={`form-control ${emailInput.modifier}`}
          type="email"
          required
          name="email"
          value={user.email}
          onChange={onChangeHandler}
        />
        {emailInput.message.content && (
          <p
            className={`help text-${emailInput.message.modifier}`}
          >
            {emailInput.message.content}
          </p>
        )}
      </div>
      <br/>
      <Form.Group>
        <Form.Label>
          First Name
        </Form.Label>
        <Form.Control
          id="firstName"
          type="text"
          required
          name="firstName"
          value={user.firstName}
          onChange={onChangeHandler}
        />
      </Form.Group>
      <br/>
      <Form.Group>
        <Form.Label>
          Last Name
        </Form.Label>
        <Form.Control
          id="lastName"
          type="text"
          required
          name="lastName"
          value={user.lastName}
          onChange={onChangeHandler}
        />
      </Form.Group>
      <br/>
      <Form.Group>
        <Form.Label>
          Phone
        </Form.Label>
        <Form.Control
          id="phone"
          type="text"
          name="phoneNumber"
          value={user.phoneNumber}
          onChange={onChangeHandler}
        />
        {phoneInput.message.content && (
          <p
            className={`help text-${phoneInput.message.modifier}`}
          >
            {phoneInput.message.content}
          </p>
        )}
      </Form.Group>
      <br/>
      {isEditing && (
        <Form.Group>
          <Form.Label>
            Photo
          </Form.Label>
          <div className="file has-name">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                accept="image/*"
                onChange={onFileChangedHandler}
              />
            </label>
            {imagePreviewUrl && (
              <>
                <div className="is-user-avatar image has-max-width is-aligned-center">
                  <img
                    className="user-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                </div>
              </>
            )}
          </div>
        </Form.Group>
      )}
      <br/>
      <Form.Group>

        <button
          type="submit"
          className={`btn btn-primary ${loading &&
          'is-loading'}`}
          disabled={!canSubmit}
        >
          <span>{useFormatMessage('UserForm.submit')}</span>
        </button>
        {!isProfile && (
          <Link to={paths.MANAGE_CUSTOMER} className="button">
            {goBackMessage}
          </Link>
        )}
      </Form.Group>
    </form>
  );
};

CustomerForm.propTypes = {
  isEditing: PropTypes.bool,
  userData: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    active: PropTypes.boolean,
    photoURL: PropTypes.string
  }),
  action: PropTypes.func.isRequired
};

export default CustomerForm;

import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import {
  doc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import { db } from 'firebase.js';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from 'firebase/storage';
import { ageCategoryOptions, classTypeOptions, levelOptions } from 'data';

export const CLASS_FETCH_DATA_INIT = createAction('CLASS_FETCH_DATA_INIT');
export const CLASS_FETCH_DATA_SUCCESS = createAction(
  'CLASS_FETCH_DATA_SUCCESS',
);
export const CLASS_FETCH_DATA_FAIL = createAction('CLASS_FETCH_DATA_FAIL');

export const CLASS_DELETE_INIT = createAction('CLASS_DELETE_INIT');
export const CLASS_DELETE_SUCCESS = createAction('CLASS_DELETE_SUCCESS');
export const CLASS_DELETE_FAIL = createAction('CLASS_DELETE_FAIL');

export const CLASS_CLEAR_DATA = createAction('CLASS_CLEAR_DATA');

export const CLASS_CREATE_INIT = createAction('CLASS_CREATE_INIT');
export const CLASS_CREATE_SUCCESS = createAction('CLASS_CREATE_SUCCESS');
export const CLASS_CREATE_FAIL = createAction('CLASS_CREATE_FAIL');

export const CLASS_MODIFY_INIT = createAction('CLASS_MODIFY_INIT');
export const CLASS_MODIFY_SUCCESS = createAction('CLASS_MODIFY_SUCCESS');
export const CLASS_MODIFY_FAIL = createAction('CLASS_MODIFY_FAIL');

export const CLASS_CLEAN_UP = createAction('CLASS_CLEAN_UP');

export const CLASS_CLEAR_DATA_LOGOUT = createAction('CLASS_CLEAR_DATA_LOGOUT');

const storage = getStorage();

export const fetchClasses = (all = false) => {
  return async (dispatch) => {
    dispatch(CLASS_FETCH_DATA_INIT());

    const classes = [];

    try {
      const ref = collection(db, 'classes');
      let q = query(ref, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);

      const q2 = query(collection(db, 'sellers'));
      const snap2 = await getDocs(q2);
      const q3 = query(collection(db, 'classCategories'));
      const snap3 = await getDocs(q3);

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const seller = snap2.docs.filter((item) => item.id == docData.sellerId);
        const category = snap3.docs.filter(
          (item) => item.id == docData.categoryId,
        );
        const level = levelOptions.find(
          (item) => item.value === docData?.levelId,
        );
        const ageCategory = ageCategoryOptions.find(
          (item) => item.value === docData?.ageCategoryId,
        );
        const classType = classTypeOptions.find(
          (item) => item.value === docData?.classTypeId,
        );

        classes.push({
          id: doc.id,
          categoryName: category[0]?.get('name'),
          ageCategoryName: ageCategory?.label,
          levelName: level?.label,
          classTypeName: classType?.label,
          sellerName: seller[0]?.get('name'),
          ...doc.data(),
        });
      });
      return dispatch(CLASS_FETCH_DATA_SUCCESS({ classes }));
    } catch (error) {
      console.log(error);
      toastr.error('', error);
      return dispatch(CLASS_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deleteClass = (id) => {
  return async (dispatch, getState) => {
    dispatch(CLASS_DELETE_INIT());
    const { locale } = getState().preferences;

    const { photoURL } = getState()
      .classes.data.filter((item) => item.id === id)
      .pop();

    const deleteLogoTask = photoURL ? deleteLogo(photoURL) : null;
    const deletePlanTask = await deleteDoc(doc(db, 'classes', id));

    try {
      await Promise.all([deleteLogoTask, deletePlanTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CLASS_DELETE_FAIL({
          error: errorMessage,
        }),
      );
    }

    toastr.success('', 'The class was deleted.');
    return dispatch(CLASS_DELETE_SUCCESS({ id }));
  };
};

export const clearClassesData = () => {
  return (dispatch) => {
    dispatch(CLASS_CLEAR_DATA());
  };
};

export const clearClassesDataLogout = () => {
  return (dispatch) => {
    dispatch(CLASS_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;
  const storageRef = ref(storage, `classes/${fileName}`);
  uploadBytesResumable(storageRef, file).then((snapshot) => {
    console.log('Uploaded a blob or file!');
  });
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/classes%2F${uid}.${fileExtension}?alt=media`;
};
const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo
    .split('classes%2F')
    .pop()
    .split('?alt=media')
    .shift();
  const desertRef = ref(storage, logoPath);
  return deleteObject(desertRef)
    .then(() => {
      // File deleted successfully
    })
    .catch(() => {
      // Uh-oh, an error occurred!
    });
};

export const createClass = (data) => {
  return async (dispatch, getState) => {
    dispatch(CLASS_CREATE_INIT());
    const { locale } = getState().preferences;
    let uploadLogoTask = null;
    let photoURL = null;
    const classData = {
      ...data,
      photoURL,
      createdAt: serverTimestamp(),
    };
    delete classData.file;
    try {
      const createClassDbTask = await addDoc(
        collection(db, 'classes'),
        classData,
      );
      classData.id = createClassDbTask.id;
      if (data.file) {
        photoURL = getLogoUrl(classData.id, data.file);
        await uploadLogo(classData.id, data.file);
        await updateDoc(doc(db, 'classes', classData.id), {
          photoURL,
        });
      }
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CLASS_CREATE_FAIL({
          error: errorMessage,
        }),
      );
    }
    toastr.success('', 'Class created successfully');
    return dispatch(CLASS_CREATE_SUCCESS(classData));
  };
};

export const modifyClass = (data) => {
  return async (dispatch, getState) => {
    dispatch(CLASS_MODIFY_INIT());
    const { locale } = getState().preferences;
    const { photoURL } = getState()
      .classes.data.filter((item) => item.id === data.id)
      .pop();

    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (data.file) {
      newLogoUrl = getLogoUrl(data.id, data.file);
      deleteLogoTask = photoURL && deleteLogo(photoURL);
      uploadLogoTask = uploadLogo(data.id, data.file);
    }

    const classData = {
      ...data,
      photoURL: newLogoUrl || photoURL || null,
      modifiedAt: serverTimestamp(),
    };
    delete classData.file;

    const updateCategoryDbTask = await updateDoc(
      doc(db, 'classes', data.id),
      classData,
    );
    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateCategoryDbTask]);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CLASS_MODIFY_FAIL({
          error: errorMessage,
        }),
      );
    }
    toastr.success('', 'Class updated successfully');

    return dispatch(CLASS_MODIFY_SUCCESS(data));
  };
};

export const classesCleanUp = () => (dispatch) => dispatch(CLASS_CLEAN_UP());

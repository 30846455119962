import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { getAuth, isSignInWithEmailLink } from "firebase/auth";
import {
  signIn,
  setPassword,
  authCleanUp,
} from 'state/actions/auth';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { inputValidations } from 'utils';
import paths from '../Router/paths';
import '../../assets/scss/style.scss';
import './Login.scss';


function Login() {
  const { error, isAuth, loading, locale } = useSelector(
    state => ({
      error: state.auth.error,
      isAuth: !!state.auth.userData.id,
      loading: state.auth.loading,
      locale: state.preferences.locale
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const auth = getAuth();

  const [authData, setAuthData] = useState({
    email: '',
    password: ''
  });

  const onChangeHandler = useChangeHandler(setAuthData);

  useEffect(() => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
    return () => {
      document.documentElement.classList.add(
        'has-aside-left',
        'has-navbar-fixed-top'
      );
      dispatch(authCleanUp());
    };
  }, [dispatch]);

  const isEmailLink = isSignInWithEmailLink(auth, window.location.href);

  const onSubmitHandler = event => {
    event.preventDefault();

    if (isEmailLink) {
      dispatch(
        setPassword(authData.email, authData.password, window.location.href)
      );
    } else {
      dispatch(signIn(authData.email, authData.password));
    }
  };

  const inputs = isEmailLink
    ? inputValidations(authData.email, authData.password, locale)
    : {
        email: {
          modifier: null,
          message: null
        },
        password: {
          modifier: null,
          message: null
        },
        canSubmit: false
      };

  const NavigateRoot = isAuth && <Navigate to={paths.ROOT} />;

  const setNewPasswordMessage = useFormatMessage('Login.setNewPassword');

  const loginMessage = useFormatMessage('Login.login');

  const setPasswordMessage = useFormatMessage('Login.setPassword');

  const forgotPasswordMessage = useFormatMessage('Login.forgotPassword');

  return (
    <section className="section">
      {NavigateRoot}
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>

          <div className="card text-center">
            <header className="card-header ">
                <div className="mb-4">
                    <Logo width='50%'/>
                </div>
                <h3>
                  {isEmailLink ? setNewPasswordMessage : loginMessage}
                </h3>
            </header>
            <div className="card-body">
              <form onSubmit={onSubmitHandler}>
                <div className="input-group mb-3">
                    <input
                      className={classNames('form-control', inputs.email.modifier)}
                      type="email"
                      name="email"
                      required
                      value={authData.email}
                      onChange={onChangeHandler}
                      placeholder={useFormatMessage('Login.email')}
                    />
                  {inputs.email.message && (
                    <p className={classNames('help', inputs.email.modifier)}>
                      {inputs.email.message}
                    </p>
                  )}
                </div>
                <div className="input-group mb-2">
                    <input
                      className={classNames('form-control', inputs.password.modifier)}
                      type="password"
                      name="password"
                      required
                      value={authData.password}
                      onChange={onChangeHandler}
                      placeholder={useFormatMessage('Login.password')}
                    />
                  {inputs.password.message && (
                    <p className={classNames('help', inputs.password.modifier)}>
                      {inputs.password.message}
                    </p>
                  )}
                </div>
                <br />
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      type="submit"
                      className={classNames('btn btn-primary shadow-2 mb-3', {
                        'is-loading': loading
                      })}
                      disabled={isEmailLink ? !inputs.canSubmit : false}
                    >
                      {isEmailLink ? setPasswordMessage : loginMessage}
                    </button>
                  </div>
                  {!isEmailLink && (
                    <div className="control">
                      <Link
                        to={paths.RESET_PASSWORD}
                        className="button is-outlined"
                      >
                        {forgotPasswordMessage}
                      </Link>
                    </div>
                  )}
                </div>
                {error && (
                  <p className={classNames('text-danger')}>{error}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

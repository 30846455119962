import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'font-awesome/css/font-awesome.min.css';

import LanguageWrapper from './components/LanguageWrapper';
import { configureStore } from './state/store';
import './index.scss';
import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';

const { store, persistor } = configureStore({});

const app = (
  <Provider store={store}>
    <LanguageWrapper>
      <PersistGate persistor={persistor}>
        <ReduxToastr
          newestOnTop={false}
          preventDuplicates
          position="bottom-right"
          getState={state => state.toastr}
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <Router />
      </PersistGate>
    </LanguageWrapper>
  </Provider>
);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);

serviceWorker.unregister();

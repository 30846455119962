import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError, FIREBASE_RESPONSE } from 'utils';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithEmailLink,
  sendPasswordResetEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth';
import { clearUsersDataLogout } from './users';

export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');

export const AUTH_RESTORE_SESSION_INIT = createAction(
  'AUTH_RESTORE_SESSION_INIT'
);
export const AUTH_RESTORE_SESSION_SUCCESS = createAction(
  'AUTH_RESTORE_SESSION_SUCCESS'
);
export const AUTH_RESTORE_SESSION_FAIL = createAction(
  'AUTH_RESTORE_SESSION_FAIL'
);

export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction(
  'AUTH_SET_PASSWORD_SUCCESS'
);
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction(
  'AUTH_RESET_PASSWORD_INIT'
);
export const AUTH_RESET_PASSWORD_SUCCESS = createAction(
  'AUTH_RESET_PASSWORD_SUCCESS'
);
export const AUTH_RESET_PASSWORD_FAIL = createAction(
  'AUTH_RESET_PASSWORD_FAIL'
);

export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');

export const AUTH_FETCH_USER_DATA_INIT = createAction(
  'AUTH_FETCH_USER_DATA_INIT'
);
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction(
  'AUTH_FETCH_USER_DATA_SUCCESS'
);
export const AUTH_FETCH_USER_DATA_FAIL = createAction(
  'AUTH_FETCH_USER_DATA_FAIL'
);

export const AUTH_CHANGE_PASSWORD_INIT = createAction(
  'AUTH_CHANGE_PASSWORD_INIT'
);
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction(
  'AUTH_CHANGE_PASSWORD_SUCCESS'
);
export const AUTH_CHANGE_PASSWORD_FAIL = createAction(
  'AUTH_CHANGE_PASSWORD_FAIL'
);

export const AUTH_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');

export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');

export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');

export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');

const auth = getAuth();
export const logout = () => async dispatch => {
  dispatch(AUTH_LOGOUT_INIT());

  dispatch(clearUsersDataLogout());
  await signOut(auth);

  dispatch(AUTH_LOGOUT_SUCCESS());
};

export const verifyAuth = () => dispatch => {
  onAuthStateChanged(auth, (user) => {
    dispatch(AUTH_RESTORE_SESSION_INIT());

    if (user !== null) {
      return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
    }

    dispatch(AUTH_RESTORE_SESSION_FAIL());
    return dispatch(logout());
  });
};

export const fetchUserData = () => async dispatch => {
  dispatch(AUTH_FETCH_USER_DATA_INIT());

  const { uid, email, displayName, photoURL } = auth.currentUser;
  const idTokenResult = await auth.currentUser.getIdTokenResult(true);
  console.log(idTokenResult);
  if (!idTokenResult.claims.isAdmin) {
    await signOut(auth);
    return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error: 'This user is not admin' }));
  }
  return dispatch(
    AUTH_FETCH_USER_DATA_SUCCESS({
      id: uid,
      email,
      isAdmin: idTokenResult.claims.isAdmin,
      name: displayName
    })
  );
};

export const checkUserData = () => (dispatch, getState) => {
  const { id } = getState().auth.userData;

  if (!id) {
    dispatch(fetchUserData());
  }
};

export const signIn = (email, password) => async (dispatch, getState) => {
  dispatch(AUTH_SIGN_IN_INIT());
  const { locale } = getState().preferences;
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.log(error);
    const errorMessage = firebaseError(error.code, locale);
    return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
  }

  const { emailVerified } = auth.currentUser;

  if (!emailVerified) {
    const errorMessage = firebaseError(
      FIREBASE_RESPONSE.USER_DISABLED,
      locale
    );
    return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
  }

  return dispatch(fetchUserData());
};

export const setPassword = (email, password, url) => async (dispatch, getState) => {
  dispatch(AUTH_SET_PASSWORD_INIT());
  const { locale } = getState().preferences;

  try {
    await signInWithEmailLink(auth, email, url);
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
  }

  const user = auth.currentUser;

  try {
    await updatePassword(user, password);
  } catch (error) {
    console.log(error);
    const errorMessage = firebaseError(error.code, locale);
    return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
  }

  dispatch(AUTH_SET_PASSWORD_SUCCESS());

  return dispatch(fetchUserData());
};

export const resetPassword = email => async (dispatch, getState) => {
  dispatch(AUTH_RESET_PASSWORD_INIT());
  const { locale } = getState().preferences;

  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.log(error);
    const errorMessage = firebaseError(error.code, locale);
    return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
  }

  return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
};

export const authCleanUp = () => dispatch => dispatch(AUTH_CLEAN_UP());

export const changeUserPassword = (currentPassword, newPassword) => async (dispatch, getState) => {
  dispatch(AUTH_CHANGE_PASSWORD_INIT());
  const { locale } = getState().preferences;

  const user = auth.currentUser;

  const { email } = user;

  const credential = EmailAuthProvider.credential(
    email,
    currentPassword
  );

  try {
    await reauthenticateWithCredential(user, credential);
  } catch (error) {
    console.log(error);
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
  }

  try {
    await updatePassword(user, newPassword);
  } catch (error) {
    const errorMessage = firebaseError(error, locale);
    toastr.error('', errorMessage);
    return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
  }

  toastr.success('', 'Password changed successfully');
  return dispatch(AUTH_CHANGE_PASSWORD_SUCCESS());
};

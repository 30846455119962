import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import avatar1 from '../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';
import { getBookings, updateStatusBookingById } from 'lib/booking';
import { useFormatDate } from 'hooks';
import RejectModal from 'components/BookingModal/rejectModal';
import ApproveModal from 'components/BookingModal/approveModal';
import { Link } from 'react-router-dom';

function Home() {
  const tabContent = (
    <div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href="#">
            <img
              className="rounded-circle"
              style={{ width: '40px' }}
              src={avatar1}
              alt="activity-user"
            />
          </a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Silje Larsen</h6>
          <span className="float-right d-flex  align-items-center">
            <i className="fa fa-caret-up f-22 m-r-10 text-c-green" />
            3784
          </span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href="#">
            <img
              className="rounded-circle"
              style={{ width: '40px' }}
              src={avatar2}
              alt="activity-user"
            />
          </a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Julie Vad</h6>
          <span className="float-right d-flex  align-items-center">
            <i className="fa fa-caret-up f-22 m-r-10 text-c-green" />
            3544
          </span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href="#">
            <img
              className="rounded-circle"
              style={{ width: '40px' }}
              src={avatar3}
              alt="activity-user"
            />
          </a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Storm Hanse</h6>
          <span className="float-right d-flex  align-items-center">
            <i className="fa fa-caret-down f-22 m-r-10 text-c-red" />
            2739
          </span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href="#">
            <img
              className="rounded-circle"
              style={{ width: '40px' }}
              src={avatar1}
              alt="activity-user"
            />
          </a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Frida Thomse</h6>
          <span className="float-right d-flex  align-items-center">
            <i className="fa fa-caret-down f-22 m-r-10 text-c-red" />
            1032
          </span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href="#">
            <img
              className="rounded-circle"
              style={{ width: '40px' }}
              src={avatar2}
              alt="activity-user"
            />
          </a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Silje Larsen</h6>
          <span className="float-right d-flex  align-items-center">
            <i className="fa fa-caret-up f-22 m-r-10 text-c-green" />
            8750
          </span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center">
        <div className="m-r-10 photo-table">
          <a href="#">
            <img
              className="rounded-circle"
              style={{ width: '40px' }}
              src={avatar3}
              alt="activity-user"
            />
          </a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Storm Hanse</h6>
          <span className="float-right d-flex  align-items-center">
            <i className="fa fa-caret-down f-22 m-r-10 text-c-red" />
            8750
          </span>
        </div>
      </div>
    </div>
  );

  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingUpdated, setLoadingUpdated] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [bookingSelected, setBookingSelected] = useState();

  const onCloseRejectModal = () => {
    setRejectModalOpen(false);
  };

  const onOpenRejectModal = (booking) => {
    setBookingSelected(booking);
    setRejectModalOpen(true);
  };

  const onCloseApproveModal = () => {
    setApproveModalOpen(false);
  };

  const onOpenApproveModal = (booking) => {
    setBookingSelected(booking);
    setApproveModalOpen(true);
  };

  // Action

  const handleRejectBooking = async (booking) => {
    setLoadingUpdated(true);
    await updateStatusBookingById(booking.id, 'Rejected');
    setLoadingUpdated(false);
    onCloseRejectModal();
  };

  const handleApproveBooking = async (booking) => {
    setLoadingUpdated(true);
    await updateStatusBookingById(booking.id, 'Confirmed');
    setLoadingUpdated(false);
    onCloseApproveModal();
  };

  useEffect(() => {
    async function fetchBooking() {
      await getBookings(setBookingData, setLoading);
    }
    fetchBooking();
  }, []);

  return (
    <div>
      <Row>
        <Col md={12} xl={6}>
          <Card className="Recent-Users">
            <Card.Header>
              <Card.Title as="h5">Recent Class Bookings</Card.Title>
            </Card.Header>
            <Card.Body className="px-0 py-2">
              {loading ? (
                <ClipLoader />
              ) : (
                <Table hover className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Bookings</th>
                      <th scope="col">Credits</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingData.length === 0 && <>There are no recent bookings</>}
                    {bookingData.map((val, index) => (
                      <tr key={index}>
                        <td>
                          <h6 className="text-muted">
                            {useFormatDate(val.createdAt.toDate(), {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            })}
                          </h6>
                          <p className="m-0 fw-bold">
                            {val.customerData?.firstName}
                            {' '}
                            {val.customerData?.lastName}
                          </p>
                          <h6 className="mb-1">
                            {val.classData?.name} &#x2022; {' '}
                            {val.classData?.seller?.name}
                          </h6>
                        </td>
                        <td>
                          <p className="m-0 fw-bold">{val.credit} credits</p>
                        </td>
                        <td>
                          <Link
                            to={`/bookings/${val.id}`}
                            className="btn btn-sm btn-primary"
                          >
                          <i className="feather icon-edit"/>
                          &nbsp;
                            Detail
                          </Link>

                          {/* <button
                            type="button"
                            class="btn btn-outline-danger btn-sm"
                            onClick={() => onOpenRejectModal(val)}
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            onClick={() => onOpenApproveModal(val)}
                          >
                            Approve
                          </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
        {/* <Col md={12} xl={6}>
          <Card className="Recent-Users">
            <Card.Header>
              <Card.Title as="h5">Recent Users</Card.Title>
            </Card.Header>
            <Card.Body className="px-0 py-2">
              <Table responsive hover>
                <tbody>
                  <tr className="unread">
                    <td>
                      <img
                        className="rounded-circle"
                        style={{ width: '40px' }}
                        src={avatar1}
                        alt="activity-user"
                      />
                    </td>
                    <td>
                      <h6 className="mb-1">Isabella Christensen</h6>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of…
                      </p>
                    </td>
                    <td>
                      <h6 className="text-muted">
                        <i className="fa fa-circle text-c-green f-10 m-r-15" />
                        11 MAY 12:56
                      </h6>
                    </td>
                    <td>
                      <a href="#" className="label theme-bg2 text-white f-12">
                        Reject
                      </a>
                      <a href="#" className="label theme-bg text-white f-12">
                        Approve
                      </a>
                    </td>
                  </tr>
                  <tr className="unread">
                    <td>
                      <img
                        className="rounded-circle"
                        style={{ width: '40px' }}
                        src={avatar2}
                        alt="activity-user"
                      />
                    </td>
                    <td>
                      <h6 className="mb-1">Mathilde Andersen</h6>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of…
                      </p>
                    </td>
                    <td>
                      <h6 className="text-muted">
                        <i className="fa fa-circle text-c-red f-10 m-r-15" />
                        11 MAY 10:35
                      </h6>
                    </td>
                    <td>
                      <a href="#" className="label theme-bg2 text-white f-12">
                        Reject
                      </a>
                      <a href="#" className="label theme-bg text-white f-12">
                        Approve
                      </a>
                    </td>
                  </tr>
                  <tr className="unread">
                    <td>
                      <img
                        className="rounded-circle"
                        style={{ width: '40px' }}
                        src={avatar3}
                        alt="activity-user"
                      />
                    </td>
                    <td>
                      <h6 className="mb-1">Karla Sorensen</h6>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of…
                      </p>
                    </td>
                    <td>
                      <h6 className="text-muted">
                        <i className="fa fa-circle text-c-green f-10 m-r-15" />9
                        MAY 17:38
                      </h6>
                    </td>
                    <td>
                      <a href="#" className="label theme-bg2 text-white f-12">
                        Reject
                      </a>
                      <a href="#" className="label theme-bg text-white f-12">
                        Approve
                      </a>
                    </td>
                  </tr>
                  <tr className="unread">
                    <td>
                      <img
                        className="rounded-circle"
                        style={{ width: '40px' }}
                        src={avatar1}
                        alt="activity-user"
                      />
                    </td>
                    <td>
                      <h6 className="mb-1">Ida Jorgensen</h6>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of…
                      </p>
                    </td>
                    <td>
                      <h6 className="text-muted f-w-300">
                        <i className="fa fa-circle text-c-red f-10 m-r-15" />
                        19 MAY 12:56
                      </h6>
                    </td>
                    <td>
                      <a href="#" className="label theme-bg2 text-white f-12">
                        Reject
                      </a>
                      <a href="#" className="label theme-bg text-white f-12">
                        Approve
                      </a>
                    </td>
                  </tr>
                  <tr className="unread">
                    <td>
                      <img
                        className="rounded-circle"
                        style={{ width: '40px' }}
                        src={avatar2}
                        alt="activity-user"
                      />
                    </td>
                    <td>
                      <h6 className="mb-1">Albert Andersen</h6>
                      <p className="m-0">
                        Lorem Ipsum is simply dummy text of…
                      </p>
                    </td>
                    <td>
                      <h6 className="text-muted">
                        <i className="fa fa-circle text-c-green f-10 m-r-15" />
                        21 July 12:56
                      </h6>
                    </td>
                    <td>
                      <a href="#" className="label theme-bg2 text-white f-12">
                        Reject
                      </a>
                      <a href="#" className="label theme-bg text-white f-12">
                        Approve
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col> */}
        {/* <Col md={12} xl={12} className='m-b-30'>
          <Tabs defaultActiveKey="today" id="uncontrolled-tab-example">
            <Tab eventKey="today" title="Today">
              {tabContent}
            </Tab>
            <Tab eventKey="week" title="This Week">
              {tabContent}
            </Tab>
            <Tab eventKey="all" title="All">
              {tabContent}
            </Tab>
          </Tabs>
        </Col> */}
      </Row>
      <ApproveModal
        show={approveModalOpen}
        onClose={onCloseApproveModal}
        onSubmit={() => handleApproveBooking(bookingSelected)}
        loading={loadingUpdated}
      />
      <RejectModal
        show={rejectModalOpen}
        onClose={onCloseRejectModal}
        onSubmit={() => handleRejectBooking(bookingSelected)}
        loading={loadingUpdated}
      />
    </div>
  );
}

export default Home;

import { Timestamp, and, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { db } from 'firebase.js';
import { toastr } from "react-redux-toastr";
export const getSellerById = async (id) => {
    try {
        const docRef = doc(db, 'sellers', id)
        const docSnap = await getDoc(docRef)
        
        if (docSnap.exists() && docSnap.data().active) {
            return {
                ...docSnap.data(),
                id: docSnap.id,
            }
        }
        return null
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getSellers = async () => {
    try {
        const data = []
        const q = query(collection(db, 'sellers'), and(where('active', '==', true)))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(async(doc) => {
            const docData = doc.data()
            data.push({
                ...docData,
                id: doc.id
            })
        })
        return data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const updateSeller = async (id, data) => {
    try {
      await updateDoc(doc(db, 'sellers', id), {
        ...data,
        modifiedAt: Timestamp.now(),
      });
      toastr.success('', 'Update Seller Successfully');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
    }
  };
  
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';
import {
  fetchPlans,
  deletePlan,
  clearPlansData,
} from '../../state/actions/plans';
import PlanConfigModal from 'components/PlanConfigModal';
import { getPlanConfig } from 'lib/config';
const Plans = () => {
  const { planList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      planList: state.plans.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.plans.error,
      loading: state.plans.loading,
      deleted: state.plans.deleted,
    }),
    shallowEqual,
  );
  const [deleteModal, setDeleteModal] = useState({
    planId: null,
    isOpen: false,
  });

  const [planConfig, setPlanConfig] = useState();

  const [isPlanConfigModalOpen, setPlanConfigModalOpen] = useState(false);

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchPlans(true));
    }

    return () => dispatch(clearPlansData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        planId: null,
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const columns = [
    {
      Header: useFormatMessage('Levels.name'),
      accessor: 'name',
    },
    {
      Header: 'Credits',
      accessor: 'credit',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Type',
      accessor: 'role',
      Cell: ({ row }) =>
        row.original.role !== null ? 'Subscription' : 'One Time',
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ row }) => (row.original.active ? 'Yes' : 'No'),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="button-actions is-right">
          <Link
            to={`/plans/${row.original.id}`}
            className="btn btn-sm btn-primary"
          >
            <i className="feather icon-edit" />
          </Link>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? planList.filter((el) => {
        const clonedElem = { name: el.name };
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : planList;

  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage packages </Card.Title>
            <NavSearch handleChange={setSearch} />
            <div className="ms-auto">
              <button
                className="btn btn-primary ms-auto"
                onClick={async () => {
                  const data = await getPlanConfig();
                  setPlanConfig(data);
                  setPlanConfigModalOpen(true);
                }}
              >
                Update content
              </button>
              <Link to={paths.ADD_PLAN} className="btn btn-primary ms-auto">
                Add package
              </Link>
              <a
                href={process.env.REACT_APP_STRIPE_PRODUCT_LINK}
                target="_blank"
                className="btn btn-primary"
              >
                Manage packages on Stripe
              </a>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>

      <PlanConfigModal
        loading={false}
        onClose={() => setPlanConfigModalOpen(false)}
        onSubmit={() => {}}
        planConfigProps={planConfig}
        show={isPlanConfigModalOpen}
      />
    </>
  );
};

export default Plans;

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Table from 'components/Table';
import SellerForm from '../../components/SellerForm';
import { createSeller, modifySeller } from '../../state/actions/sellers';
import paths from '../Router/paths';
import { doc, getDoc } from '@firebase/firestore';
import { db } from 'firebase.js';
import Slider from 'components/Slider';
import { getStorage, ref } from '@firebase/storage';
import { useFormatDate, useFormatMessage } from 'hooks';
import { sortDate } from 'utils';
import ReviewModal from 'components/ReviewModal';
import {
  createReview,
  deleteReview,
  getReviewBySellerIdSnapShot,
  updateReview,
} from 'lib/review';
import { Timestamp } from 'firebase/firestore';
import ConfirmationModal from 'components/ConfirmationModal';
import SellerReviewForm from 'components/SellerReviewForm';
import { updateSeller } from 'lib/seller';

const storage = getStorage();

const Seller = () => {
  const { id } = useParams();
  const [reviews, setReview] = useState([]);
  const { success, isAdmin } = useSelector(
    (state) => ({
      success: state.sellers.success,
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [seller, setSeller] = useState({});
  const [selectedReview, setSelectedReview] = useState();
  useEffect(() => {
    const fetchSellerData = async () => {
      const sellerRef = doc(db, 'sellers', id);
      const response = await getDoc(sellerRef);
      return response.data();
    };

    if (id) {
      fetchSellerData()
        .then((sellerData) => {
          setSeller({
            ...sellerData,
            createdAt: sellerData.createdAt,
            id,
          });
        })
        .catch(() => {
          setSeller({ error: true });
        });
    }
  }, [id]);

  useEffect(() => {
    if (seller) {
      getReviewBySellerIdSnapShot(seller.id, setReview, setLoading);
    }
  }, [seller]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Navigate to={paths.ROOT} />;
  const redirect = (seller.error || success) && (
    <Navigate to={paths.MANAGE_SELLER} />
  );
  const sellerForm =
    !seller.name && id ? (
      <ClipLoader />
    ) : (
      <SellerForm
        sellerData={seller}
        action={isEditing ? modifySeller : createSeller}
      />
    );
  let slider = [];
  if (seller.slider) {
    slider = seller.slider.map((x) => {
      const fileName = x.url
        .split(`sellers%2F${id}%2F`)
        .pop()
        .split('?alt=media')
        .shift();
      return {
        ...x,
        status: 'FINISH',
        storageRef: ref(storage, `sellers/${id}/${fileName}`),
      };
    });
  }
  const sliderForm =
    !seller.name && id ? (
      <ClipLoader />
    ) : (
      <Slider slider={slider} sellerId={id} />
    );

  const handleSellerReviewSubmit = async (data) => {
    await updateSeller(id, data);
  };

  const reviewForm =
    !seller.name && id ? (
      <ClipLoader />
    ) : (
      <SellerReviewForm
        seller={seller}
        handleSubmit={handleSellerReviewSubmit}
      />
    );

  const columns = [
    {
      Header: 'Avatar',
      accessor: 'customerAvatar',
      Cell: ({ row }) => (
        <div className="is-user-avatar image has-max-width is-aligned-center">
          {row.original.customerAvatar && (
            <img
              className="user-avatar"
              src={row.original.customerAvatar}
              alt="User profile logo preview"
            />
          )}
        </div>
      ),
    },
    {
      Header: 'Customer Name',
      accessor: 'customerName',
    },
    {
      Header: 'Comment',
      accessor: 'comment',
    },
    {
      Header: 'Rating',
      accessor: 'rating',
    },

    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: 'Modified At',
      accessor: 'modifiedAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.modifiedAt instanceof Timestamp &&
            useFormatDate(row.original.modifiedAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="button-actions is-right">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => onOpenModal(row.original)}
          >
            <i className="feather icon-edit" />
          </button>

          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => onOpenDeleteModal(row.original)}
          >
            <i className="feather icon-trash" />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const handleReviewSubmit = async (data) => {
    setLoading(true);
    const payload = {
      ...data,
      sellerId: seller.id,
    };
    if (data?.id) {
      await updateReview(payload);
    } else {
      // Create New
      await createReview(payload);
    }
    onCloseModal();
    setLoading(false);
  };

  const onOpenModal = (data) => {
    setSelectedReview(data || undefined);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setSelectedReview(undefined);
  };

  const onOpenDeleteModal = (data) => {
    setOpenDeleteModal(true);
    setSelectedReview({
      createdAt: new Date().getTime(),
      ...data,
    });
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedReview(undefined);
  };

  const onDeleteLevelHandler = () => {
    if (selectedReview) {
      deleteReview(selectedReview.id, selectedReview);
    }
    onCloseDeleteModal();
  };

  const deleteMessage = useFormatMessage('Levels.delete');

  const confirmMessage = useFormatMessage('Levels.confirm');

  const permDeleteMessage = useFormatMessage('Levels.permDelete');

  const cancelMessage = useFormatMessage('Levels.cancel');

  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">
                {isEditing ? 'Edit Seller' : 'Add Seller'}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Tabs defaultActiveKey="basic">
                <Tab eventKey="basic" title="Basic Info">
                  {sellerForm}
                </Tab>
                <Tab eventKey="setting" title="Image Slider" disabled={!id}>
                  {sliderForm}
                </Tab>
                <Tab eventKey="reviews" title="Reviews & Rating" disabled={!id}>
                  {reviewForm}
                  <Card>
                    <Card.Header>
                      <div className="d-flex flex-row align-items-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => onOpenModal()}
                        >
                          Add new review
                        </button>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="b-table">
                        <Table columns={columns} data={reviews} />
                      </div>
                    </Card.Body>
                  </Card>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ReviewModal
        show={openModal}
        onClose={onCloseModal}
        selectedReview={selectedReview}
        onSubmit={handleReviewSubmit}
        loading={loading}
      />
      <ConfirmationModal
        isActive={openDeleteModal}
        isLoading={loading}
        confirmButtonMessage={deleteMessage}
        title={confirmMessage}
        body={permDeleteMessage}
        cancelButtonMessage={cancelMessage}
        onConfirmation={onDeleteLevelHandler}
        onCancel={() => setOpenDeleteModal(false)}
      />
    </>
  );
};

export default Seller;

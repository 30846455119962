import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import en from 'date-fns/locale/en-US';
import { shallowEqual, useSelector } from 'react-redux';
import './DatePicker.scss';

registerLocale('en', en);

const dateFormat = (locale) => {
  switch (locale) {
    case 'en':
      return 'dd/MM/yyyy h:mm aa';
    default:
      return 'dd/MM/yyyy h:mm aa';
  }
};

function DatePickerStyled({ name, date, setState, showTimeSelect, minDate, disabled }) {
  const dateValue = date || new Date().getTime();

  const onDateChangedHandler = (value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value && value.getTime(),
    }));

  const { locale } = useSelector(
    (state) => ({
      locale: state.preferences.locale,
    }),
    shallowEqual,
  );

  return (
    <DatePicker
      locale={locale}
      showTimeSelect={showTimeSelect}
      dateFormat={dateFormat(locale)}
      selected={dateValue}
      onChange={onDateChangedHandler}
      minDate={minDate}
      disabled={disabled}
    />
  );
}

DatePickerStyled.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  setState: PropTypes.func.isRequired,
  showTimeSelect: PropTypes.boolean,
  minDate: PropTypes.instanceOf(Date)
};

export default DatePickerStyled;

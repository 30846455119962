import { useChangeHandler } from 'hooks';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { validateRating } from 'utils';

export default function SellerReviewForm({ seller, handleSubmit }) {
  
  const [sellerReview, setSellerReview] = useState({
    averageRating: seller?.averageRating,
    reviewCounting: seller?.reviewCounting,
  });

  let averageInput = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  const invalidAverage =
    sellerReview.averageRating && !validateRating(sellerReview.averageRating);

  if (invalidAverage) {
    averageInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: 'Invalid Average Rating (From 0 To 5, .e.g 2, 4.5,..)',
      },
    };
  }

  const onChangeHandler = useChangeHandler(setSellerReview);
  const canSubmit = sellerReview.averageRating && sellerReview.reviewCounting && !invalidAverage;
  return (
    <>
      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              disabled={!canSubmit}
              onClick={() => handleSubmit(sellerReview)}
            >
              Save
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <form>
            <Form.Group>
              <Form.Label htmlFor="name">Average Rating</Form.Label>
              <Form.Control
                id="averageRating"
                type="number"
                required
                name="averageRating"
                placeholder='4.5'
                value={sellerReview?.averageRating}
                onChange={onChangeHandler}
              />
            </Form.Group>
            {averageInput.message.content && (
              <p className={`help text-${averageInput.message.modifier}`}>
                {averageInput.message.content}
              </p>
            )}
            <br />
            <Form.Group>
              <Form.Label htmlFor="name">Review Counting</Form.Label>
              <Form.Control
                id="reviewCounting"
                type="number"
                placeholder='1200'
                required
                name="reviewCounting"
                value={sellerReview?.reviewCounting}
                onChange={onChangeHandler}
              />
            </Form.Group>
          </form>
        </Card.Body>
      </Card>
    </>
  );
}

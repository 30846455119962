export default {
  ROOT: '/',
  LOGIN: '/login',
  MANAGE_USER: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  MANAGE_CUSTOMER: '/customers',
  ADD_CUSTOMER: '/customers/new',
  MODIFY_CUSTOMER: '/customers/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  MANAGE_CLASS: '/classes',
  MODIFY_CLASS: '/classes/:id',
  ADD_CLASS: '/classes/new',
  MANAGE_CLASS_CAT: '/class-categories',
  MODIFY_CLASS_CAT: '/class-categories/:id',
  ADD_CLASS_CAT: '/class-categories/new',
  MANAGE_SELLER: '/sellers',
  MODIFY_SELLER: '/sellers/:id',
  ADD_SELLER: '/sellers/new',
  MANAGE_PLAN: '/plans',
  ADD_PLAN: '/plans/new',
  MODIFY_PLAN: '/plans/:id',
  MANAGE_BOOKING: '/bookings',
  ADD_BOOKING: '/bookings/new',
  MODIFY_BOOKING: '/bookings/:id',
  MANAGE_SUB: '/subscriptions',
  ADD_SUB: '/subscriptions/new',
  MODIFY_SUB: '/subscriptions/:id',
  MANAGE_TRANS: '/transactions',
  MANAGE_CONTACT: '/contacts',
  MANAGE_CORPORATE: '/corporates',
  MANAGE_PARTNER: '/partners',
  MANAGE_ORDER: '/orders',
  MANAGE_SLIDE: '/slide-show',
  ADD_SLIDE: '/slide-show/new',
  MODIFY_SLIDE: '/slide-show/:id',
  MANAGE_VOUCHER: '/vouchers',
  ADD_VOUCHER: '/voucher/new',
  MODIFY_VOUCHER: '/voucher/:id',
  MANAGE_FEEDBACK: '/feedback'
};

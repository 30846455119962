import { and, collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore"
import { db } from 'firebase.js';
import { getSellers } from "./seller";
export const getClassById = async (id) => {
    try {
        const docRef = doc(db, 'classes', id)
        const docSnap = await getDoc(docRef)
        
        if (docSnap.exists() && docSnap.data().active) {
            return {
                ...docSnap.data(),
                id: docSnap.id,
            }
        }
        return null
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getClassCategories = async (setData) => {
    try {
      const q = query(collection(db, 'classCategories'));
      onSnapshot(q, async (querySnapshot) => {
        const data = [];
        
        querySnapshot.docs.map(async (doc) => {
          const docData = doc.data();
          data.push({
            ...docData,
            id: doc.id,
          });
        }),
          setData(data);
      });
    } catch (err) {
      console.error(err);
      setData([]);
    }
  };
  

  export const getClasses = async () => {
    try {
        const data = []
        const q = query(collection(db, 'classes'), and(where('active', '==', true)))
        const querySnapshot = await getDocs(q)
        const sellerData = await getSellers()
        querySnapshot.forEach(async(doc) => {
            const docData = doc.data()
            const seller = sellerData.find((val) => val.id === docData.sellerId)
            data.push({
                ...docData,
                id: doc.id,
                seller
            })
        })
        return data
    } catch (error) {
        console.error(error)
        return []
    }
}
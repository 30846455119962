import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import NavRight from './NavRight';
import { collapseMenuAction } from '../../../state/actions/preferences';

function NavBar () {
  const {
    headerBackColor,
    headerFixedLayout,
    collapseMenu
  } = useSelector(
    state => ({
      headerBackColor: state.headerBackColor,
      headerFixedLayout: state.headerFixedLayout,
      collapseMenu: state.collapseMenu
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const onToggleNavigation = () => {
    dispatch(collapseMenuAction());
  };
  let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', headerBackColor];
  if (headerFixedLayout) {
    headerClass = [...headerClass, 'headerpos-fixed'];
  }

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <header className={headerClass.join(' ')}>
      <div className="m-header">
        <a className={toggleClass.join(' ')} id="mobile-collapse1" href='#'
           onClick={onToggleNavigation}><span>&nbsp;</span></a>
        <a href='#' className="b-brand">
          <div className="b-bg">
            <i className="feather icon-trending-up"/>
          </div>
          <span className="b-title">LyfeGo Admin</span>
        </a>
      </div>
      <a className="mobile-menu" id="mobile-header" href='#'><i
        className="feather icon-more-horizontal"/><span>&nbsp;</span></a>
      <div className="collapse navbar-collapse">
        <NavRight/>
      </div>
    </header>
  );
}

export default NavBar;

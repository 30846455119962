import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Users from 'pages/Users';
import Customers from 'pages/Customers';
import Customer from 'pages/Customer';
import Seller from 'pages/Seller';
import Sellers from 'pages/Sellers';
import ClassCategories from 'pages/ClassCategories';
import ClassCategory from 'pages/ClassCategory';
import Profile from 'pages/Profile';
import Class from 'pages/Class';
import Classes from 'pages/Classes';
import Plans from 'pages/Plans';
import Plan from 'pages/Plan';
import Bookings from 'pages/Bookings';
import Booking from 'pages/Booking';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import Contacts from 'pages/Contacts';
import Corporates from 'pages/Corporates';
import Partners from 'pages/Partners';
import Subscriptions from 'pages/Subscriptions';
import Orders from 'pages/Orders';
import Transactions from 'pages/Transactions';
import SlideShows from 'pages/SlideShows';
import SlideShow from 'pages/SlideShow';
import FeedBacks from 'pages/FeedBacks';
import Vouchers from 'pages/Vouchers';
import Voucher from 'pages/Voucher';

function RouterComponent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={paths.LOGIN} element={<Login />} />
        <Route exact path={paths.RESET_PASSWORD} element={<ResetPassword />} />
        <Route exact path={paths.ROOT} element={<PrivateRoute ><Home/></PrivateRoute>} />
        <Route exact path={paths.PROFILE}  element={<PrivateRoute ><Profile/></PrivateRoute>} />
        <Route exact path={paths.ADD_USER}  element={<PrivateRoute ><User/></PrivateRoute>} />
        <Route exact path={paths.MODIFY_USER}  element={<PrivateRoute ><User/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_USER}  element={<PrivateRoute ><Users/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_CUSTOMER}  element={<PrivateRoute ><Customers/></PrivateRoute>} />
        <Route exact path={paths.MODIFY_CUSTOMER}  element={<PrivateRoute ><Customer/></PrivateRoute>} />
        <Route exact path={paths.ADD_CUSTOMER}  element={<PrivateRoute ><Customer/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_SELLER}  element={<PrivateRoute ><Sellers/></PrivateRoute>} />
        <Route exact path={paths.MODIFY_SELLER}  element={<PrivateRoute ><Seller/></PrivateRoute>} />
        <Route exact path={paths.ADD_SELLER}  element={<PrivateRoute ><Seller/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_CLASS_CAT}  element={<PrivateRoute ><ClassCategories/></PrivateRoute>} />
        <Route exact path={paths.MODIFY_CLASS_CAT}  element={<PrivateRoute ><ClassCategory/></PrivateRoute>} />
        <Route exact path={paths.ADD_CLASS_CAT}  element={<PrivateRoute ><ClassCategory/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_CLASS}  element={<PrivateRoute ><Classes/></PrivateRoute>} />
        <Route exact path={paths.MODIFY_CLASS}  element={<PrivateRoute ><Class/></PrivateRoute>} />
        <Route exact path={paths.ADD_CLASS}  element={<PrivateRoute ><Class/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_PLAN}  element={<PrivateRoute ><Plans /></PrivateRoute>} />
        <Route exact path={paths.MODIFY_PLAN}  element={<PrivateRoute ><Plan/></PrivateRoute>} />
        <Route exact path={paths.ADD_PLAN}  element={<PrivateRoute ><Plan/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_BOOKING}  element={<PrivateRoute ><Bookings /></PrivateRoute>} />
        <Route exact path={paths.MODIFY_BOOKING}  element={<PrivateRoute ><Booking/></PrivateRoute>} />
        <Route exact path={paths.ADD_BOOKING}  element={<PrivateRoute ><Booking/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_CONTACT}  element={<PrivateRoute ><Contacts/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_FEEDBACK}  element={<PrivateRoute ><FeedBacks/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_CORPORATE}  element={<PrivateRoute ><Corporates/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_PARTNER}  element={<PrivateRoute ><Partners/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_SUB}  element={<PrivateRoute ><Subscriptions/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_ORDER}  element={<PrivateRoute ><Orders/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_TRANS}  element={<PrivateRoute ><Transactions/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_SLIDE}  element={<PrivateRoute ><SlideShows/></PrivateRoute>} />
        <Route exact path={paths.ADD_SLIDE}  element={<PrivateRoute ><SlideShow/></PrivateRoute>} />
        <Route exact path={paths.MODIFY_SLIDE}  element={<PrivateRoute ><SlideShow/></PrivateRoute>} />
        <Route exact path={paths.MANAGE_VOUCHER}  element={<PrivateRoute ><Vouchers/></PrivateRoute>} />
        <Route exact path={paths.ADD_VOUCHER}  element={<PrivateRoute ><Voucher/></PrivateRoute>} />
        <Route exact path={paths.MODIFY_VOUCHER}  element={<PrivateRoute ><Voucher/></PrivateRoute>} />
        <Route element={NotFound} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;

export const levelOptions = [
  {
    label: 'Beginner',
    value: 'beginner',
  },
  {
    label: 'Intermediate',
    value: 'intermediate',
  },
  {
    label: 'Advanced',
    value: 'advanced',
  },
  {
    label: 'All Levels',
    value: 'all-levels',
  },
];

export const classTypeOptions = [
  {
    label: 'Group',
    value: 'group',
  },
  {
    label: 'Private',
    value: 'private',
  },
];

export const ageCategoryOptions = [
  {
    label: 'Kids',
    value: 'kids',
  },
  {
    label: 'Teen',
    value: 'teen',
  },
  {
    label: 'Adults',
    value: 'adults',
  },
];

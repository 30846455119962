import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';
import { useFormatDate } from 'hooks';
import { sortDate } from 'utils';
import { Timestamp } from 'firebase/firestore';
import { getCorporates } from 'lib/corporate';

export default function Corporates() {
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [corporateList, setCorporateList] = useState([]);
  const { isAdmin } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );
  const fetchCorporate = async () => {
    await getCorporates(setCorporateList, setLoading);
  };
  useEffect(() => {
    if (isAdmin) {
      fetchCorporate();
    }
  }, [isAdmin]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const columns = [
    {
      Header: 'Business Email',
      accessor: 'businessEmail',
    },
    {
      Header: 'Corporator',
      accessor: 'corporator',
      Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`,
    },
    {
      Header: 'Job title',
      accessor: 'jobTitle',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdAt instanceof Timestamp &&
            useFormatDate(row.original.createdAt.toDate(), {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
        </small>
      ),
    },
  ];

  const data = search
    ? corporateList.filter((el) => {
        const clonedElem = {
          businessEmail: el.businessEmail,
          corporator: `${el.firstName} ${el.lastName}`,
          phone: el.phone || '',
          jobTitle: el.jobTitle || '',
        };
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : corporateList;

  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage Corporate</Card.Title>
            <NavSearch handleChange={setSearch} />
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? (
              <ClipLoader />
            ) : (
              <Table
                columns={columns}
                data={data}
                style={{
                  overflowX: 'auto',
                  width: '100%',
                  display: 'block',
                }}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import { useChangeHandler, useFormatDate, useFormatMessage } from 'hooks';
import { validateEmail } from 'utils';
import './style.scss';

const UserForm = ({ isEditing, isProfile, userData, action }) => {
  const { loading } = useSelector(
    state => ({
      loading: state.users.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  const [user, setUser] = useState(userData);

  const onChangeHandler = useChangeHandler(setUser);

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...user, createdAt: user.createdAt, isEditing, isProfile })
    );
  };

  let emailInput = {
    modifier: null,
    message: { modifier: null, content: null }
  };

  const invalidEmail = user.email && !validateEmail(user.email);

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  if (invalidEmail) {
    emailInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: invalidEmailMessage
      }
    };
  }

  const canSubmit =
    user.name && !invalidEmail;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  const emailMessage = useFormatMessage('UserForm.email');

  const adminMessage = useFormatMessage('UserForm.admin');
  return (
    <Row>
      <Col lg={6} sm={6}>
        <Card>
          <Card.Header className="card-header">
            <span className="icon pr-1">
              <i className="feather icon-user default"/>
            </span>
            {useFormatMessage('UserForm.userInfo')}
          </Card.Header>
          <Card.Body>
            <form onSubmit={onSubmitHandler}>
              <Form.Group>
                <Form.Label>{emailMessage}</Form.Label>
                <input
                  className={`form-control ${emailInput.modifier}`}
                  type="email"
                  required
                  name="email"
                  value={user.email}
                  onChange={onChangeHandler}
                />
                {emailInput.message.content && (
                  <p
                    className={`help text-${emailInput.message.modifier}`}
                  >
                    {emailInput.message.content}
                  </p>
                )}
              </Form.Group>
              <br/>
              <Form.Group>
                <Form.Label>
                  {useFormatMessage('UserForm.name')}
                </Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  required
                  name="name"
                  value={user.name}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <br/>
              {!isProfile && (
                <Form.Group>
                  <Form.Check
                    custom
                    type="switch"
                    name="isAdmin"
                    id="isAdmin"
                    checked={user.isAdmin}
                    onChange={onChangeHandler}
                    label="Is Admin"
                  />
                </Form.Group>
              )}
              <br/>
              <p>Notification settings</p>
              <Form.Group>
                <Form.Check
                  custom
                  type="switch"
                  name="getBookingEmail"
                  id="getBookingEmail"
                  checked={user.getBookingEmail}
                  onChange={onChangeHandler}
                  label="Get Booking Email"
                />
              </Form.Group>
              <hr/>
              <Form.Group>
                <button
                  type="submit"
                  className={`btn btn-primary ${loading &&
                  'is-loading'}`}
                  disabled={!canSubmit}
                >
                  <span>{useFormatMessage('UserForm.submit')}</span>
                </button>
                {!isProfile && (
                  <Link to={paths.MANAGE_USER} className="button">
                    {goBackMessage}
                  </Link>
                )}
              </Form.Group>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

UserForm.propTypes = {
  isEditing: PropTypes.bool,
  userData: PropTypes.shape({
    id: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    getBookingEmail: PropTypes.bool
  }),
  action: PropTypes.func.isRequired
};

export default UserForm;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { plansCleanUp, clearPlansData } from 'state/actions/plans';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { Button } from 'bootstrap';

const PlanForm = ({ planData, action }) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.plans.loading,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!planData.id) dispatch(clearPlansData());
    return () => dispatch(plansCleanUp());
  }, [planData.id, dispatch]);

  const [plan, setPlan] = useState(planData);
  const [validated, setValidated] = useState(false);

  const onChangeHandler = useChangeHandler(setPlan);

  const onSubmitHandler = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(action({ ...plan }));
    }
    setValidated(true);
  };

  return (
    <Row>
      <Col lg={12}>
        <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Active</Form.Label>
            <Form.Check
              custom
              type="switch"
              name="active"
              id="active"
              checked={plan.active}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label htmlFor="name">Name *</Form.Label>
            <Form.Control
              id="name"
              type="text"
              required
              name="name"
              value={plan.name}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label htmlFor="credit">Credits *</Form.Label>
            <Form.Control
              id="credit"
              type="number"
              required
              name="credit"
              value={plan.credit}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label htmlFor="price">Price *</Form.Label>
            <Form.Control
              id="price"
              type="number"
              required
              name="price"
              value={plan.price}
              disabled={!!planData.id}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label htmlFor="description">Description</Form.Label>
            <Form.Control
              id="description"
              as="textarea"
              required
              name="description"
              rows={3}
              value={plan.description}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          {/* <Form.Group>
            <Form.Label htmlFor="description">
              Additional Description
              <button
                style={{
                  marginLeft: '8px',
                }}
                className="btn btn-primary btn-sm"
                onClick={(e) => {
                  setPlan((prev) => ({
                    ...prev,
                    addDescription:
                      prev?.['addDescription']?.length >= 0
                        ? [...prev['addDescription'], '']
                        : [''],
                  }));
                }}
              >
                +
              </button>
            </Form.Label>
            {plan?.addDescription?.map((e, index) => (
              <InputGroup
                style={{
                  marginBottom: '4px',
                }}
              >
                <Form.Control
                  id="addDescription"
                  required
                  name={`addDescription.${index}`}
                  value={plan?.addDescription?.[index]}
                  onChange={(e) => {
                    const addDescription = plan['addDescription'];
                    addDescription[index] = e.target.value;
                    setPlan((prev) => ({
                      ...prev,
                      addDescription,
                    }));
                  }}
                />
                <InputGroup.Text
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    const addDes = plan['addDescription'];
                    addDes.splice(index, 1);
                    setPlan((prev) => ({
                      ...prev,
                      addDescription: addDes,
                    }));
                  }}
                >
                  <i
                    className="feather icon-trash"
                    style={{
                      fontSize: '14px',
                    }}
                  />
                </InputGroup.Text>
              </InputGroup>
            ))}
          </Form.Group> */}
          {/* <hr />  */}
          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading && 'is-loading'}`}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_PLAN} className="button">
              {useFormatMessage('UserForm.goBack')}
            </Link>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};

PlanForm.propTypes = {
  planData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    credit: PropTypes.number,
    price: PropTypes.number,
    active: PropTypes.boolean,
  }),
  action: PropTypes.func.isRequired,
};

export default PlanForm;

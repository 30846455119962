import {
  collectionGroup,
  collection,
  getDocs,
  getDoc,
  orderBy,
  query,
} from 'firebase/firestore';
import { db } from 'firebase.js';
import { getCustomers } from './customer';

export const getSubscriptions = async () => {
  try {
    const q = query(
      collectionGroup(db, 'subscriptions'),
      orderBy('created', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    const data = [];
    const customerData = await getCustomers();
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      const customerId = doc.ref.parent.parent.id;
      const customer = customerData.find((cus) => cus.id == customerId);
      data.push({
        customerId: customerId,
        customerName: `${customer?.firstName} ${customer?.lastName}`,
        email: customer?.email,
        ...docData,
        id: doc.id,
      });
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

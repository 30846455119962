import { Button, Modal } from 'react-bootstrap';

export default function RejectModal({ show, onClose, onSubmit, loading }) {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold' style={{
          color: 'black',
          fontSize: '18px',
          
        }}>Reject booking</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-danger'>Are you sure reject this booking?</Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit} disabled={loading} className='btn-sm btn-danger'>{loading ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            'Reject'
          )}</Button>
      </Modal.Footer>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import PlanForm from '../../components/PlanForm';
import { createPlan, fetchPlans, modifyPlan } from '../../state/actions/plans';
import paths from '../Router/paths';

const Plan = () => {
  const { id } = useParams();

  const { planList, success, isAdmin } = useSelector(
    (state) => ({
      planList: state.plans.data,
      success: state.plans.success,
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );

  const [plan, setPlan] = useState({});

  useEffect(() => {
    if (id) {
      console.log(planList);

      const plan = planList.find((item) => item.id === id);
      console.log(plan);
      if (plan) {
        setPlan(plan);
      }
    }
  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Navigate to={paths.ROOT} />;
  const redirect = (plan.error || success) && (
    <Navigate to={paths.MANAGE_PLAN} />
  );
  const planForm =
    !plan.name && id ? (
      <ClipLoader />
    ) : (
      <PlanForm planData={plan} action={isEditing ? modifyPlan : createPlan} />
    );

  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">
                {isEditing ? 'Edit Plan' : 'Add Plan'}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text class="help text-danger">
                Plan products will be created and updated on Stripe also.
                Therefore it will be delayed a bit sometimes.
              </Card.Text>
              {planForm}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Plan;

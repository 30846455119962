import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { sellersCleanUp, clearSellersData } from 'state/actions/sellers';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SellerForm = ({ sellerData, action }) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.sellers.loading,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!sellerData.id) dispatch(clearSellersData());
    return () => dispatch(sellersCleanUp());
  }, [sellerData.id, dispatch]);

  const [seller, setSeller] = useState(sellerData);

  const onChangeHandler = useChangeHandler(setSeller);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action({ ...seller }));
  };

  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setSeller((prevState) => ({ ...prevState, file, photoURL: null }));
  };

  const canSubmit = seller.name;
  const imagePreviewUrl = !seller.photoURL
    ? seller.file && URL.createObjectURL(seller.file)
    : seller.photoURL;

  return (
    <Row>
      <Col lg={12}>
        <form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Active</Form.Label>
            <Form.Check
              custom
              type="switch"
              name="active"
              id="active"
              checked={seller.active}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label htmlFor="name">Name *</Form.Label>
            <Form.Control
              id="name"
              type="text"
              required
              name="name"
              value={seller.name}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label htmlFor="address">Address *</Form.Label>
            <Form.Control
              id="address"
              type="text"
              required
              name="address"
              value={seller.address}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label htmlFor="info">Info *</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={seller.info}
              config={{
                removePlugins: ['EasyImage', 'ImageUpload', "MediaEmbed", 'Table'],
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSeller((prevState) => ({ ...prevState, info: data }));
              }}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Photo</Form.Label>
            <div className="file has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept="image/*"
                  onChange={onFileChangedHandler}
                />
              </label>
              {imagePreviewUrl && (
                <>
                  <div className="is-user-avatar image has-max-width is-aligned-center">
                    <img
                      className="user-avatar"
                      src={imagePreviewUrl}
                      alt="User profile logo preview"
                    />
                  </div>
                </>
              )}
            </div>
          </Form.Group>
          <hr />
          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading && 'is-loading'}`}
              disabled={!canSubmit}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_SELLER} className="button">
              {useFormatMessage('UserForm.goBack')}
            </Link>
          </Form.Group>
        </form>
      </Col>
    </Row>
  );
};

SellerForm.propTypes = {
  sellerData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    info: PropTypes.string,
    address: PropTypes.string,
    active: PropTypes.boolean,
    photoURL: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default SellerForm;

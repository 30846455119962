export const genderOptions = { male: 'Male', female: 'Female' };
export const booleanOptions = { 0: 'No', 1: 'Yes' };
export const locationOptions = {
  north: 'North',
  central: 'Central',
  south: 'South',
  east: 'East',
  west: 'West',
};
export const bookingStatus = {
  canceled: 'Canceled',
  pending: 'Pending',
  confirmed: 'Confirmed',
  rejected: 'Rejected',
  done: 'Done',
};
export const contactOptions = [
  {
    value: 'myPlanOrAccount',
    label: 'My package or account',
  },
  {
    value: 'reservations',
    label: 'Reservations',
  },
  {
    value: 'billing',
    label: 'Billing',
  },
  {
    value: 'technicalIssues',
    label: 'Technical Issues',
  },
  {
    value: 'feedback',
    label: 'Feedback',
  },
];

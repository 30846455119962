import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from 'firebase.js';

export const getPartners = async (setData, setLoading) => {
  try {
    const q = query(collection(db, 'partners'), orderBy('createdAt', 'desc'));
    onSnapshot(q, async (querySnapshot) => {
      const data = [];
      setLoading(true)
      querySnapshot.docs.map(async (doc) => {
        const docData = doc.data();
        data.push({
          ...docData,
          id: doc.id,
        });
      }),
        setData(data);
        setLoading(false)
    });
  } catch (err) {
    console.error(err);
    setLoading(false)
    setData([]);
  }
};

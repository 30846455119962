import paths from './pages/Router/paths';

export default {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/',
          icon: 'feather icon-home',
          badge: {
            title: 'New',
            type: 'label-danger',
          },
        },
      ],
    },
    {
      id: 'customer',
      title: 'Customers',
      type: 'group',
      icon: 'icon-users',
      children: [
        {
          id: 'student',
          title: 'Manage Customer',
          type: 'item',
          icon: 'feather icon-user',
          url: paths.MANAGE_CUSTOMER,
        },
        {
          id: 'feedback',
          title: 'Feedbacks',
          type: 'item',
          icon: 'feather icon-star',
          url: paths.MANAGE_FEEDBACK,
        },
      ],
    },
    {
      id: 'class',
      title: 'Class',
      type: 'group',
      icon: 'icon-pages',
      children: [
        {
          id: 'manage-seller',
          title: 'Manage Sellers',
          type: 'item',
          url: paths.MANAGE_SELLER,
          icon: 'feather icon-users',
        },
        {
          id: 'manage-classes',
          title: 'Manage Classes',
          type: 'item',
          url: paths.MANAGE_CLASS,
          icon: 'feather icon-grid',
        },
        {
          id: 'manage-classe-category',
          title: 'Manage Class Categories',
          type: 'item',
          url: paths.MANAGE_CLASS_CAT,
          icon: 'feather icon-layers',
        },
      ],
    },
    {
      id: 'subscription',
      title: 'Packages',
      type: 'group',
      icon: 'icon-pages',
      children: [
        {
          id: 'manage-classes',
          title: 'Manage Packages',
          type: 'item',
          url: paths.MANAGE_PLAN,
          icon: 'feather icon-aperture',
        },
        // {
        //   id: 'manage-subscriptions',
        //   title: 'Manage Subscriptions',
        //   type: 'item',
        //   url: paths.MANAGE_SUB,
        //   icon: 'feather icon-book',
        // },
      ],
    },
    {
      id: 'sales',
      title: 'Sales',
      type: 'group',
      icon: 'icon-charts',
      children: [
        {
          id: 'bookings',
          title: 'Manage Bookings',
          type: 'item',
          icon: 'feather icon-calendar',
          url: paths.MANAGE_BOOKING,
        },
        {
          id: 'orders',
          title: 'Manage Orders',
          type: 'item',
          icon: 'feather icon-shopping-cart',
          url: paths.MANAGE_ORDER,
        },
        {
          id: 'trans',
          title: 'Manage Transactions',
          type: 'item',
          icon: 'feather icon-minimize-2',
          url: paths.MANAGE_TRANS,
        },
        {
          id: 'vouchers',
          title: 'Manage Vouchers',
          type: 'item',
          icon: 'feather icon-bookmark',
          url: paths.MANAGE_VOUCHER,
        },
      ],
    },

    {
      id: 'systems',
      title: 'Systems',
      type: 'group',
      icon: 'icon-lock',
      isAdmin: true,
      children: [
        {
          id: 'slideShows',
          title: 'Slide Shows',
          type: 'item',
          url: '/slide-show',
          icon: 'feather icon-users',
        },
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          url: '/users',
          icon: 'feather icon-users',
        },
        {
          id: 'contacts',
          title: 'Contacts',
          type: 'item',
          url: '/contacts',
          icon: 'feather icon-message-square',
        },
        {
          id: 'partners',
          title: 'Partners',
          type: 'item',
          url: '/partners',
          icon: 'feather icon-share-2',
        },
        {
          id: 'corporates',
          title: 'Corporates',
          type: 'item',
          url: '/corporates',
          icon: 'feather icon-users',
        },
      ],
    },
  ],
};

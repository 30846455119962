import { Button, Modal } from 'react-bootstrap';

export default function ApproveModal({ show, onClose, onSubmit, loading }) {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title
          className="fw-bold"
          style={{
            color: 'black',
            fontSize: '18px',
          }}
        >
          Approve booking
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure approve this booking?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onSubmit}
          className="btn-sm btn-primary"
          disabled={loading}
        >
          {loading ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            'Approve'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

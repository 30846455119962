import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import { createUser, modifyUser, updateCredit } from 'state/actions/customers';
import paths from 'pages/Router/paths';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'firebase.js';
import { useFormatMessage } from 'hooks';
import { Card, Tab, Tabs } from 'react-bootstrap';
import CustomerForm from 'components/CustomerForm';
import CreditForm from 'components/CustomerForm/CreditForm';
import Membership from 'components/CustomerForm/Membership';

function Customer() {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    (state) => ({
      success: state.customers.success,
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual,
  );

  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, 'customers', id);
      const response = await getDoc(userRef);
      return response.data();
    };
    if (id) {
      fetchUserData()
        .then((userData) => {
          console.log(userData);
          setUser({
            ...userData,
            id,
          });
        })
        .catch((error) => {
          console.log(error);
          setUser({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;
  const userForm =
    !user.email && id ? (
      <ClipLoader />
    ) : (
      <CustomerForm
        isEditing={isEditing}
        userData={user}
        action={isEditing ? modifyUser : createUser}
      />
    );
  const creditForm =
    !user.email && id ? null : (
      <CreditForm isEditing={isEditing} userData={user} action={updateCredit} />
    );
  const membership = !user.email && id ? null : <Membership id={id} />;
  const NavigateAdmin = !isAdmin && <Navigate to={paths.ROOT} />;
  const NavigateUser = (user.error || success) && (
    <Navigate to={paths.MANAGE_CUSTOMER} />
  );

  const editUserMessage = useFormatMessage('User.editUser');

  const newUserMessage = useFormatMessage('User.newUser');
  return (
    <div>
      {NavigateAdmin}
      {NavigateUser}
      <Card>
        <Card.Header>
          <Card.Title as="h5">
            {isEditing ? editUserMessage : newUserMessage}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Tabs defaultActiveKey="basic">
            <Tab eventKey="basic" title="Basic Info">
              {userForm}
            </Tab>
            <Tab eventKey="setting" title="Balance Credits" disabled={!id}>
              {creditForm}
            </Tab>
            {/* <Tab eventKey="membership" title="Membership Plan" disabled={!id}>
              {membership}
            </Tab> */}
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Customer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Card } from 'react-bootstrap';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import {
  slideShowsCleanUp,
  clearSlideShowsData,
} from 'state/actions/slideShows';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { checkIsBlob } from 'utils';

const SlideShowForm = ({ slideShowData, action }) => {
  const { loading, slideShowList } = useSelector(
    (state) => ({
      loading: state.slideShows.loading,
      slideShowList: state.slideShows.data,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const [slideShow, setSlideShow] = useState(slideShowData);

  const onChangeHandler = useChangeHandler(setSlideShow);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action({ ...slideShow, images: imageList, removedImage }));
  };

  const imageColumns = [
    {
      Header: 'Image',
      accessor: 'url',
      Cell: ({ row }) => (
        <div className="is-user-avatar image has-max-width is-aligned-center">
          {row.original.url && (
            <img
              width={'auto'}
              height={100}
              src={row.original.url}
              alt="User profile logo preview"
            />
          )}
        </div>
      ),
    },
    {
      Header: 'Sort Order',
      accessor: 'sortOrder',
      Cell: ({ row }) => (
        <Form.Control
          id="sortOrder"
          type="number"
          required
          name="sortOrder"
          value={row.original.sortOrder}
          onChange={(e) => onChangeSortOrder(e, row.index)}
        />
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="button-actions is-right">
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={(e) => removeImageItem(row.index)}
          >
            <i className="feather icon-trash" />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const [imageList, setImageList] = useState([]);
  const [removedImage, setRemovedImage] = useState([]);

  const onFileChangedHandler = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const blobList = Array.from(files).map((item) => ({
        url: URL.createObjectURL(item),
        sortOrder: 0,
        file: item,
      }));
      setImageList((prev) => [...prev, ...blobList]);
    }
  };

  const onChangeSortOrder = (e, index) => {
    if (imageList.length > 0) {
      setImageList((prev) =>
        prev.map((item, i) =>
          i === index
            ? {
                ...item,
                sortOrder: Number(e.target.value),
              }
            : { ...item },
        ),
      );
    }
  };

  const removeImageItem = (index) => {
    if (imageList.length > 0) {
      if (!checkIsBlob(imageList[index].url)) {
        setRemovedImage((prev) => [...prev, imageList[index]]);
      }
      const imgList = [...imageList];
      imgList.splice(index, 1);
      setImageList(imgList.sort((a, b) => a - b));
    }
  };

  let identifierInput = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  const invalidIdentifier =
    slideShow.identifier &&
    slideShowList.find(
      (item) =>
        item.identifier === slideShow.identifier && slideShow.id !== item.id,
    );

  if (invalidIdentifier) {
    identifierInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: 'Duplicate identifier name',
      },
    };
  }
  const canSubmit = slideShow.name && !invalidIdentifier;
  useEffect(() => {
    if (!slideShowData.id) dispatch(clearSlideShowsData());
    return () => dispatch(slideShowsCleanUp());
  }, [slideShowData.id, dispatch]);

  useEffect(() => {
    if (slideShow && slideShow?.images?.length > 0) {
      const slide = slideShow.images.map((item) => ({
        url: item.url,
        sortOrder: item.sortOrder,
        file: undefined,
      }));
      setImageList(slide);
    }
  }, [slideShow]);

  return (
    <Row>
      <Col lg={12}>
        <form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label htmlFor="identifier">Identifier *</Form.Label>
            <Form.Control
              id="identifier"
              type="text"
              required
              name="identifier"
              value={slideShow.identifier}
              onChange={onChangeHandler}
            />
          </Form.Group>
          {identifierInput.message.content && (
            <p className={`help text-${identifierInput.message.modifier}`}>
              {identifierInput.message.content}
            </p>
          )}
          <br />
          <Form.Group>
            <Form.Label htmlFor="name">Name *</Form.Label>
            <Form.Control
              id="name"
              type="text"
              required
              name="name"
              value={slideShow.name}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <br />

          <Card>
            <Card.Header>
              <div className="d-flex flex-row align-items-center">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  Upload images
                  <input
                    type="file"
                    accept="images/*"
                    multiple
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      minWidth: '100%',
                      minHeight: ' 100%',
                      fontSize: '100px',
                      textAlign: 'right',
                      filter: 'alpha(opacity=0)',
                      opacity: '0',
                      outline: 'none',
                      cursor: 'inherit',
                      display: 'block',
                    }}
                    onChange={onFileChangedHandler}
                  />
                </button>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="b-table">
                <Table
                  columns={imageColumns}
                  data={imageList.sort((a, b) => b.sortOrder - a.sortOrder)}
                />
              </div>
            </Card.Body>
          </Card>

          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading && 'is-loading'}`}
              disabled={!canSubmit}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_SLIDE} className="button">
              {useFormatMessage('UserForm.goBack')}
            </Link>
          </Form.Group>
        </form>
      </Col>
    </Row>
  );
};

SlideShowForm.propTypes = {
  slideShowData: PropTypes.shape({
    id: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object),
  }),
  action: PropTypes.func.isRequired,
};

export default SlideShowForm;
